import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

import {ButtonColors} from "@medispend/common/src/components/MSButton/types";
import {MSFormSelect} from "@medispend/common/src/components/MSFormSelect";
import {FormField, SectionInfo} from "@medispend/common/src/formBuilder/types";
import {FilterValue} from "@medispend/common/src/types";
import {SectionFields} from "./SectionFields";
import {sortSectionsAsc} from "@medispend/common/src/utils/sort";
import {MSBooleanToggle} from "@medispend/common/src/components/MSBooleanToggle";
import {toggleOptions} from "./constants";


interface SectionViewProps {
    screenInfo: {sections: SectionInfo[], screenUiLabel: string, screenId: string},
    sectionInfo: SectionInfo,
    onSectionChange: (value: FilterValue) => void,
    onEditField: (field: FormField) => void,
    onChangeSectionInfo: (field: FormField) => void,
    handleChangeSectionInfo: (sectionInfo: SectionInfo) => void,
    editableField: FormField
}

export const SectionView: FC<SectionViewProps> = ({screenInfo, onSectionChange, onEditField, sectionInfo, onChangeSectionInfo, handleChangeSectionInfo, editableField}) => {
    const [selectedSection, setSelectedSection] = useState(sectionInfo);
    useEffect(() => {
        setSelectedSection(sectionInfo);
    }, [sectionInfo]);

    const handleHiddenToggleChange = useCallback(
        (isHidden: boolean) => handleChangeSectionInfo({...selectedSection, isHidden}),
        [selectedSection, handleChangeSectionInfo]
    );

    return <div>
        <h2 className="header">
            {screenInfo.screenUiLabel}
            {selectedSection?.isHideable &&
                <MSBooleanToggle
                    value={selectedSection.isHidden}
                    handleChange={handleHiddenToggleChange}
                    options={toggleOptions}
                    fieldLabel=""
                    fieldId="hide-section"
                    isRequired={false}
                />
            }
        </h2>
        {screenInfo.sections.length > 1 && (
            <>
                <h3>Section</h3>
                <MSFormSelect
                    name="fieldType"
                    isDisabled={false}
                    activeVariant={null}
                    options={screenInfo.sections.sort(sortSectionsAsc)}
                    fieldLabel=""
                    placeholder="Select Section"
                    isRequired={false}
                    error=""
                    placeVariant="grid"
                    size="md"
                    value={sectionInfo?.value}
                    handleChange={(value) => {
                        onSectionChange(value);
                    }}
                    variant={ButtonColors.white}
                />
            </>
        )}

        {selectedSection &&
        <DndProvider backend={HTML5Backend}>
            <SectionFields
                sectionInfo={{...selectedSection, fields: selectedSection.fields?.filter(((field: FormField) => !field.isHidden))}}
                hiddenFields={selectedSection.fields?.filter(((field: FormField) => field.isHidden))}
                onEditField={onEditField}
                onRemoveField={onChangeSectionInfo}
                handleChangeSectionInfo={handleChangeSectionInfo}
                editableField={editableField}
            />
        </DndProvider>
        }
    </div>;
};
