import React, {ReactElement, useLayoutEffect} from "react";

import {FormField} from "@medispend/common/src/formBuilder/types";
import {useDragAndDrop} from "@medispend/common/hooks/useDragAndDrop";


interface EmptyFieldProps {
    field: FormField,
    onEditField?: (field: FormField) => void
    onRemoveField: (field: FormField) => void,
    id: string,
    moveField: (dragIndex: FormField, hoverIndex: FormField) => void,
    rowIndex: number
}

export const EmptyField = ({field, moveField}: EmptyFieldProps): ReactElement<EmptyFieldProps> => {
    const {opacity, drop} = useDragAndDrop(moveField, field);
    useLayoutEffect(() => {
        if (drop.current) {
            drop.current.setAttribute("draggable", "true");
        }
    }, [field, drop]);
    return <div className="section-field" ref={drop} style={{opacity, border: "none", paddingLeft: 0}}>
    </div>;
};
