import {AUDIENCE, CLIENT_ID} from "@medispend/common/src/constants/authentication";
import {getAuth0Config} from "@medispend/common/src/utils/getAuth0Config";
import {isProductionMode, PORT} from "@medispend/admin-common/src/constants";


const redirectUri = isProductionMode ? `${window.location.origin}/#/` : `http://${window.location.hostname}:${PORT}/#/`;

export const Auth0Config = getAuth0Config(CLIENT_ID, redirectUri);

export const Auth0options = {
    audience: AUDIENCE,
    returnTo: redirectUri
};
