import React, {useRef} from "react";
import {useDrag, useDrop} from "react-dnd";

import {FormField} from "../src/formBuilder/types";
import {ListItem} from "@medispend/cff/src/common/types";


export const useDragAndDrop = (moveField: (item: FormField, field: FormField | ListItem) => void, field: FormField | ListItem):
{opacity: number, ref: React.RefObject<HTMLDivElement>, drag: any, drop: any} => {

    const ref = useRef(null);
    const [{isOver}, drop] = useDrop({
        accept: "card",
        collect(monitor) {
            return {
                isOver: monitor.isOver()
            };
        },
        hover(item: {field: FormField, id: string}) {
            !isOver && moveField(item.field, field);
        }
    });
    const [{isDragging}, drag] = useDrag({
        type: "card",
        item: {field},
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        canDrag: true

    }, [field]);

    const opacity = isDragging ? 0 : 1;

    return {
        opacity,
        ref,
        drag,
        drop
    };
};
