import React, {FC, useEffect, useState} from "react";
import {useFormik} from "formik";
import * as _ from "lodash";
import * as Yup from "yup";

import MSInput from "@medispend/common/src/components/MSInput";
import {MSFormSelect} from "@medispend/common/src/components/MSFormSelect";
import {ButtonColors, ButtonSizes} from "@medispend/common/src/components/MSButton/types";
import {MSButton} from "@medispend/common/src/components/MSButton";
import {getRequiredMessage, getAlphaNumericWithoutSpaceMessage, REGULAR_EXP} from "@medispend/common/src/constants";
import {useQuery} from "@medispend/common/src/components/MSLayout";
import {ResponseState} from "@medispend/common/src/types";
import notification, {MessagesType} from "@medispend/common/src/components/MSNotifications";
import {FormField, SectionInfo} from "@medispend/common/src/formBuilder/types";
import {MSLoader} from "@medispend/common/src/components/MSLoader";
import {createUpdateAjax} from "../services/ajax";
import {ScreenStatesInfo, YupObject, ListFieldType} from "../common/types";
import {useExistingListsContext} from "../context/ExistingListsContext";
import {AdditionalFieldsWrapper} from "./formFields/AdditionalFieldsWrapper";
import {EDITABLE_SCREEN_STATES, FIELD_TYPE_COMPONENTS} from "./constants";
import {PageLeavingGuard} from "@medispend/common/src/components/PageLeavingGuard";


const FIELDS_TYPE = [
    {uiLabel: "Checkbox", value: "Checkbox"},
    {uiLabel: "Currency", value: "CURRENCY"},
    {uiLabel: "Date", value: "DATE"},
    {uiLabel: "Dropdown", value: "CUSTOM_DROPDOWN"},
    {uiLabel: "Dropdown", value: "STANDARD_DROPDOWN", isHidden: true},
    {uiLabel: "Hyperlink", value: "HYPERLINK"},
    {uiLabel: "Multi-Select", value: "CUSTOM_MULTISELECT"},
    {uiLabel: "Multi-Select", value: "STANDARD_MULTISELECT", isHidden: true},
    {uiLabel: "Number", value: "NUMBER"},
    {uiLabel: "Radio Button", value: "Radio Button"},
    {uiLabel: "Text", value: "TEXT"},
    {uiLabel: "Text Area", value: "TEXT_AREA"},
    {uiLabel: "Toggle", value: "CUSTOM_TOGGLE"},
    {uiLabel: "Toggle", value: "STANDARD_TOGGLE", isHidden: true}
];

interface CreateUpdateFieldStateProps {
    sectionInfo: SectionInfo,
    showAvailableFields: (field?: FormField, action?: string) => void,
    screenState: EDITABLE_SCREEN_STATES,
    editableField: FormField,
    updateSectionWithList: (list: ListFieldType[], listName: "dropdown" | "toggle") => void
}

export const CreateUpdateFieldState: FC<CreateUpdateFieldStateProps> = ({sectionInfo, showAvailableFields, screenState, editableField, updateSectionWithList}) => {
    const defaultValue = {
        fieldId: "",
        fieldLabel: "",
        fieldDescription: "",
        fieldType: ""
    };
    const query = useQuery();
    const [isLoading, setIsLoading] = useState(false);
    const {dropdowns, toggles} = useExistingListsContext();
    const [showPendingChangesModal, setShowPendingChangesModal] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [fieldValues, setFieldValues] = useState({...defaultValue, ...editableField});
    const handleApproveLeavingPage = () => {
        setHasChanges(false);
        setShowPendingChangesModal(false);
        showAvailableFields();
    };
    const screenStateInfo: ScreenStatesInfo = {
        [EDITABLE_SCREEN_STATES.CREATE_FIELD]: {
            fieldIdRegExp: REGULAR_EXP.alphaNumericRegExp,
            requestMethod: "post",
            title: "Create New Field",
            fieldParams: defaultValue,
            message: "Field was created successfully",
            action: "create"
        },
        [EDITABLE_SCREEN_STATES.EDIT_FIELD]: {
            fieldIdRegExp: REGULAR_EXP.idRegExp,
            requestMethod: "put",
            title: "Field Properties",
            fieldParams: {...defaultValue, ...editableField},
            message: "Field was updated successfully",
            action: "update"
        }
    };
    const defaultValidationRules = {
        fieldId: Yup.string()
            .matches(screenStateInfo[screenState].fieldIdRegExp, getAlphaNumericWithoutSpaceMessage("Field Identifier"))
            .required(getRequiredMessage("Field Identifier")),
        fieldLabel: Yup.string()
            .required(getRequiredMessage("Field Label")),
        fieldDescription: Yup.string(),
        fieldType: Yup.mixed()
            .required(getRequiredMessage("Field Type"))
    };
    const validationSchema = Yup.object().shape(defaultValidationRules);
    const [schema, updateSchema] = useState(validationSchema);

    const formik = useFormik({
        initialValues: {...defaultValue, ...editableField},
        onSubmit: values => {
            const clientId = query.get("clientId");
            const clientInfo = clientId ? `?clientId=${clientId}` : "";
            setIsLoading(true);

            createUpdateAjax(screenStateInfo[screenState].requestMethod, `/api/section/${sectionInfo.sectionId}/field${clientInfo}`, values, false)
                .subscribe((response: ResponseState) => {
                    if (response.error) {
                        notification(response.error?.response.message, MessagesType.ERROR);
                    } else if (response.data) {
                        const updatedValue = response.data;
                        if (response.data.toggle) {
                            updatedValue.toggleId = response.data.toggle.id;
                        }
                        if (response.data.dropdown) {
                            updatedValue.dropdownId = response.data.dropdown.id;
                        }
                        showAvailableFields(updatedValue, screenStateInfo[screenState].action);
                        notification(screenStateInfo[screenState].message, MessagesType.SUCCESS);
                    }
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 3000);

                });
        },
        validationSchema: schema
    });

    useEffect(() => {
        setHasChanges(!_.isEqual(fieldValues, _.pick(formik.values, Object.keys(fieldValues))));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values]);

    useEffect(() => {
        (editableField?.fieldId !== formik.values?.fieldId) && formik.resetForm({values: screenStateInfo[screenState].fieldParams as FormField});
        setFieldValues({...defaultValue, ...editableField});
        return () => {
            formik.resetForm({});
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editableField]);

    useEffect(() => {
        updateSectionWithList(dropdowns, "dropdown");
    }, [dropdowns]);

    useEffect(() => {
        updateSectionWithList(toggles, "toggle");
    }, [toggles]);

    const {errors, touched, values} = formik;
    const handleChangeFieldType = (validationRules: YupObject ) => {
        updateSchema(Yup.object().shape({...defaultValidationRules, ...validationRules}));
    };
    return <>
        <PageLeavingGuard
            hasChanges={hasChanges}
            showModal={showPendingChangesModal}
            setShowModal={setShowPendingChangesModal}
            handleApproveLeavingPage={handleApproveLeavingPage}
        />
        <h2>{screenStateInfo[screenState].title}</h2>
        <form onSubmit={formik.handleSubmit}>
            <div className="fields-wrapper">
                <MSInput
                    name="fieldId"
                    fieldLabel="Field Identifier"
                    isRequired={false}
                    isDisabled={screenState === EDITABLE_SCREEN_STATES.EDIT_FIELD}
                    error={(touched.fieldId && errors.fieldId) || ""}
                    value={formik.values.fieldId}
                    handleChange={(value) => formik.setFieldValue("fieldId", value)}
                    variant="grid"
                />
                <MSInput
                    name="fieldLabel"
                    fieldLabel="Field Label"
                    isRequired={false}
                    isDisabled={false}
                    error={(touched.fieldLabel && errors.fieldLabel) || ""}
                    value={formik.values.fieldLabel}
                    handleChange={(value) => formik.setFieldValue("fieldLabel", value)}
                    isHandleAction
                    variant="grid"
                />
                <MSInput
                    name="fieldDescription"
                    fieldLabel="Field Description"
                    isRequired={false}
                    isDisabled={false}
                    error={(touched.fieldDescription && errors.fieldDescription) || ""}
                    value={formik.values.fieldDescription}
                    handleChange={(value) => formik.setFieldValue("fieldDescription", value)}
                    isHandleAction
                    variant="grid"
                />
                <MSFormSelect
                    name="fieldType"
                    activeVariant={null}
                    options={screenState === EDITABLE_SCREEN_STATES.CREATE_FIELD ? FIELDS_TYPE.filter(option => !option.isHidden
                    ) : FIELDS_TYPE}
                    value={values.fieldType}
                    fieldLabel="Field Type"
                    placeholder="Select Field Type"
                    isRequired={false}
                    isDisabled={screenState === EDITABLE_SCREEN_STATES.EDIT_FIELD}
                    error={(touched.fieldType && errors.fieldType) || ""}
                    placeVariant="grid"
                    size="md"
                    handleChange={(value) => formik.setFieldValue("fieldType", value)}
                    variant={ButtonColors.white}
                />
                <AdditionalFieldsWrapper
                    fieldType={formik.values.fieldType as keyof typeof FIELD_TYPE_COMPONENTS | undefined}
                    formik={formik}
                    handleChangeFieldType={handleChangeFieldType}
                    editableField={editableField}
                />
            </div>
            <MSButton
                size={ButtonSizes.sm}
                variant={ButtonColors.green}
                onClick={formik.handleSubmit}>
                <div className="ms-button-title">
                    Save
                </div>
            </MSButton>
            <MSButton
                size={ButtonSizes.sm}
                variant={ButtonColors.grey100}
                onClick={() => {
                    if (hasChanges) {
                        return setShowPendingChangesModal(true);
                    }
                    showAvailableFields();
                }}
            >
                Cancel
            </MSButton>
        </form>
        {isLoading && <MSLoader />}
    </>;
};
