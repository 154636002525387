import React, {FC} from "react";
import {FormikValues} from "formik";
import * as Yup from "yup";
import * as _ from "lodash";

import MSInput from "@medispend/common/src/components/MSInput";
import {ComponentsTypes} from "@medispend/common/src/formBuilder/constantsLib";
import {MSFormSelect} from "@medispend/common/src/components/MSFormSelect";
import {ButtonColors} from "@medispend/common/src/components/MSButton/types";
import {
    getRequiredMessage,
    getNumericMessage,
    getPositiveNumericMessage,
    getStringMaxLengthMessage,
    getDecimalPlacesLengthMessage, REGULAR_EXP
} from "@medispend/common/src/constants";
import {FormField} from "@medispend/common/src/formBuilder/types";
import {TextTypeField, YupObject, NumberTypeField} from "../../common/types";
import {useRegisterFields} from "../hooks/useRegisterFields";
import {REQUIRED_TYPE} from "../constants";


interface NumberTypeFieldsProps {
    formik: FormikValues,
    handleChangeFieldType: (validationRules: YupObject) => void,
    editableField: FormField
}

export const NumberTypeFields: FC<NumberTypeFieldsProps> = ({formik, handleChangeFieldType, editableField}) => {
    const {errors, touched, values} = formik;
    const initialValues: NumberTypeField = {
        decimalPlaces: 0,
        maxFieldLength: null,
        defaultValue: "",
        requiredType: "OPTIONAL"
    };
    const editableFieldValues = _.pick(editableField, ["decimalPlaces", "maxFieldLength", "defaultValue", "requiredType"]);
    const validationSchema = {
        maxFieldLength: Yup.number().typeError(getNumericMessage("Maximum Length")).positive(getPositiveNumericMessage("Maximum Length")).nullable(),
        defaultValue: Yup.string()
            .matches(REGULAR_EXP.numberWithDotComma, getNumericMessage("Default Value"))
            .test("test-decimal-places", getDecimalPlacesLengthMessage(),
                (value, data: any) => {
                    let decimalPlaces = "";
                    if (value?.includes(".") || value?.includes(",")) {
                        const afterComma = value.split(",")[1];
                        const afterDot = value.split(".")[1];
                        decimalPlaces = afterComma || afterDot;
                    }
                    return decimalPlaces ? decimalPlaces.length <= data.from[0].value.decimalPlaces : true;
                }).nullable()
            .test("test-string-length", "Default Value length should be no more than Max Field Length",
                (value, data: any) => (value?.length && data.from[0].value.maxFieldLength) ? value?.length <= data.from[0].value.maxFieldLength : true),
        decimalPlaces: Yup.number().typeError(getNumericMessage("Decimal Places"))
            .max(8, getStringMaxLengthMessage("Decimal Places", 8))
            .nullable().integer("Decimal Places should be whole number"),
        requiredType: Yup.mixed()
            .required(getRequiredMessage("Required Type"))
    };
    const fieldsList = ["maxFieldLength", "defaultValue", "requiredType", "decimalPlaces"];

    useRegisterFields(handleChangeFieldType, validationSchema, initialValues, (editableFieldValues as TextTypeField), fieldsList, formik);

    return (<>
        <MSInput
            name="decimalPlaces"
            fieldLabel="Decimal Places"
            isRequired={false}
            isDisabled={false}
            error={(touched.decimalPlaces && errors.decimalPlaces) || ""}
            value={values.decimalPlaces}
            handleChange={(value) => {
                formik.setFieldValue("decimalPlaces", value.replace(/[^0-9]/g, ""));
            }}
            variant="grid"
        />
        <MSInput
            name="maxFieldLength"
            fieldLabel="Maximum Length"
            isRequired={false}
            isDisabled={false}
            error={(touched.maxFieldLength && errors.maxFieldLength) || ""}
            value={values.maxFieldLength}
            handleChange={(value) => formik.setFieldValue("maxFieldLength", value.replace(/[^0-9]/g, ""))}
            variant="grid"
        />
        <MSInput
            name="defaultValue"
            fieldLabel="Default Value"
            isRequired={false}
            isDisabled={false}
            error={(touched.defaultValue && errors.defaultValue) || ""}
            value={values.defaultValue}
            handleChange={(value) => formik.setFieldValue("defaultValue", value)}
            isHandleAction
            variant="grid"
        />
        <MSFormSelect
            name="requiredType"
            isDisabled={false}
            activeVariant={null}
            options={REQUIRED_TYPE}
            fieldLabel="Required Field"
            placeholder="Select"
            value={values.requiredType}
            isRequired={false}
            error={(touched.requiredType && errors.requiredType) || ""}
            placeVariant="grid"
            size="md"
            handleChange={(value) => formik.setFieldValue("requiredType", value)}
            variant={ButtonColors.white}
        />
    </>);
};
