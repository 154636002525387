import React, {
    FunctionComponent,
    useState,
    useEffect
} from "react";
import classNames from "classnames";

import {ButtonSizes, ButtonColors, ButtonTypes} from "../MSButton/types";
import {MenuTypes, SelectOption} from "../MSDropdown/BaseSelectWithSearch/types";
import {BaseSelectWithSearch} from "../MSDropdown/BaseSelectWithSearch";
import {FilterValue} from "../../types";
import {FieldHandleAction, FormFieldHint, Mode} from "../../formBuilder/types";
import {MSLabel} from "../MSLabel";

import "./index.scss";


export interface MSSelectProps {
    options?: SelectOption[];
    title?: string;
    disabled?: boolean;
    placeholder?: string;
    size?: keyof typeof ButtonSizes;
    buttonType?: keyof typeof ButtonTypes;
    variant?: typeof ButtonColors[keyof typeof ButtonColors];
    activeVariant?: typeof ButtonColors[keyof typeof ButtonColors] | null;
    value?: any;
    handleChange?: (optionData: FilterValue, name: string, isHandleAction: boolean, handleAction: FieldHandleAction) => void;
    isHandleAction?: boolean;
    handleAction?: FieldHandleAction;
    fieldLabel: string;
    isRequired: boolean;
    error: string;
    name?: string;
    placeVariant?: "form" | "grid";
    mode?: Mode;
    shouldShowClear?: boolean;
    isPencilDisplayed?: boolean;
    isDisabled?: boolean;
    showTypeToSearch?: boolean;
    hint?: FormFieldHint;
}


export const MSFormMultiSelect: FunctionComponent<MSSelectProps> = (props) => {
    const {
        options,
        placeholder = "Please select",
        placeVariant = "form",
        buttonType = ButtonTypes.default,
        shouldShowClear = false,
        error,
        isRequired,
        fieldLabel,
        value = [],
        handleChange,
        name,
        isHandleAction,
        handleAction,
        mode, isPencilDisplayed,
        isDisabled,
        showTypeToSearch = false,
        hint
    } = props;

    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        if (value?.length) {
            const foundOptions = options.filter(o => value.includes(o.value));
            setSelectedOptions(foundOptions);
        } else {
            setSelectedOptions([]);
        }
    }, [value, options]);

    const handleClick = (optionData: SelectOption) => {
        const optionsExists = selectedOptions?.some(o => (
            o.value === optionData.value
        ));

        const nextOptions = optionsExists
            ? selectedOptions.filter(o => o.value !== optionData.value)
            : [...(selectedOptions || []), optionData];
        setSelectedOptions(nextOptions);
        const newSelectedOptions = nextOptions.map(option => option.value);
        handleChange && handleChange(newSelectedOptions.length ? newSelectedOptions : null, name, isHandleAction, handleAction);
    };

    const handleClearOptions = () => {
        setSelectedOptions(null);
        handleChange && handleChange(null, name, isHandleAction, handleAction);
    };
    const multiSelectContent = <>
        {fieldLabel && <MSLabel isRequired={isRequired} fieldLabel={fieldLabel} isPencilDisplayed={isPencilDisplayed} error={error} />}
        <div className={classNames("ms-form-multi-select", {"has-error": error?.length})}>
            {mode === "READ" ? <div className="value">{selectedOptions?.map(o => o.uiLabel).join(", ") || "-"}</div> :
                <BaseSelectWithSearch
                    disabled={isDisabled}
                    selectedOptions={selectedOptions}
                    activeVariant={null}
                    options={options}
                    dropdownTitle={selectedOptions?.map(o => o.uiLabel).join(", ") || placeholder}
                    buttonType={buttonType}
                    size="md"
                    autoClose="outside"
                    shouldShowClear={shouldShowClear}
                    handleClearOptions={handleClearOptions}
                    variant={ButtonColors.grey100}
                    onClick={handleClick}
                    menuType={MenuTypes.checkbox}
                    error={error}
                    showTypeToSearch={showTypeToSearch}
                />}
            {!!hint && <div className={classNames("field-hint", `field-hint--${hint.type}`)}>{hint.text}</div>}
            {!!error.length && <div className="error-message">{error}</div>}
        </div>
    </>;

    return (
        <>
            {placeVariant === "form" && (
                <div className="ms-form-group">
                    {multiSelectContent}
                </div>
            )}
            {placeVariant === "grid" && (
                <>
                    {multiSelectContent}
                </>
            )}
        </>
    );
};
