import classNames from "classnames";
import React, {FC} from "react";
import {Button, Mode} from "../../formBuilder/types";
import MSInput, {MSInputProps} from "../MSInput";
import {MSLabel} from "../MSLabel";
import "./styles.scss";

interface MSHyperlinkProps extends MSInputProps {
    displayValue: string;
    button?: Button;
}

//TODO: This component will be properly implemented in the scope of EM-19819
export const MSHyperlink: FC<MSHyperlinkProps> = (props: MSHyperlinkProps) => {
    const {value, mode, displayValue, fieldLabel, button, error, isRequired, isPencilDisplayed, hint} = props;
    const Button = button?.component;
    return (
        <div className="hyperlink">
            {mode === Mode.READ ? (
                <div className="ms-form-group">
                    {fieldLabel && (
                        <MSLabel
                            isRequired={isRequired}
                            fieldLabel={fieldLabel}
                            isPencilDisplayed={isPencilDisplayed}
                            error={error}
                        />
                    )}
                    <div>
                        <a className="value" href={value} target="_blank" rel="noreferrer">
                            {displayValue || value || "-"}
                        </a>
                        {!!hint && <div className={classNames("field-hint", `field-hint--${hint.type}`)}>{hint.text}</div>}
                    </div>
                </div>
            ) : (
                <MSInput {...props} />
            )}
            {Button && (
                <Button
                    actionHandler={button.actionHandler}
                    onClick={button.actionHandler}
                    label={button.uiLabel}
                    isDisabled={button.isDisabled}
                    title={button.uiLabel}
                />
            )}
        </div>
    );
};
