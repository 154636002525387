import React, {FunctionComponent} from "react";
import {Route, Switch} from "react-router-dom";

import {CffScreenList} from "./CffScreenList";
import {EditableScreen} from "./EditableScreen";
import {
    getAllowedSubdomainsFromAuth0User,
    getRolesFromAuth0User
} from "@medispend/admin-common/src/utils/auth0Util";
import {userHasRole} from "@medispend/common/src/utils";
import {useAuth0} from "@auth0/auth0-react";
import {ACCESS_DENIED, USER_ROLE} from "./constants/settings";
import {isMedispendSubDom, subdomain} from "@medispend/admin-common/src/env";


export const App: FunctionComponent = () => {
    const {user} = useAuth0();
    const userRoles = getRolesFromAuth0User(user);
    const subdomains = getAllowedSubdomainsFromAuth0User(user);

    if (userHasRole(USER_ROLE.CFF_MEDISPEND_ADMIN, userRoles) ||
        (!isMedispendSubDom && userHasRole(USER_ROLE.CFF_CLIENT_ADMIN, userRoles) && subdomains.includes(subdomain))) {
        return (
            <div>
                <Switch>
                    <Route exact path="/" component={CffScreenList} />
                    <Route exact path="/screen/:screenId/field" component={EditableScreen} />
                </Switch>
            </div>
        );
    } else {
        return (<div>{ACCESS_DENIED}</div>);
    }
};
