import React, {FC} from "react";

import {FormField} from "@medispend/common/src/formBuilder/types";
import {MSIcon} from "@medispend/common/src/components/MSIcon";


interface AvailableFieldProps {
    field: FormField,
    onAddField: (field: FormField) => void,
    maxYPosition: number
}
export const AvailableField: FC<AvailableFieldProps> = ({field, onAddField, maxYPosition}) => {
    return (<div className="available-field">
        <button
            onClick={() => onAddField({...field, isHidden: false, yPosition: (maxYPosition || 0) + 1, xPosition: 1})}
            data-testid="add-button"
        >
            <MSIcon icon="plus-circle" />
        </button>
        <span className="available-field__label">{field.fieldLabel}</span>
    </div>);
};
