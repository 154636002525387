import React, {useEffect} from "react";
import ReactDOM from "react-dom";

import {AppsList} from "@medispend/admin-common/src/constants";
import {AdminLayout} from "@medispend/admin-common/src/AdminLayout";
import {AppWithAuth0} from "@medispend/common/src/authentication/auth0/components/AppWithAuth0";
import {MSGlobalModal} from "@medispend/common/src/components/MSGlobalModal";
import {Auth0Config, Auth0options} from "./constants";
import {App} from "./App";

import "./scss/style.scss";


const DashboardRoot = () => {
    useEffect(() => {
        document.title = "@medispend/cff";
    }, []);
    return (<AppWithAuth0 auth0Config={Auth0Config}>
        <MSGlobalModal>
            <AdminLayout render={() => (<App />)} app={AppsList.CFF} auth0options={Auth0options} />
        </MSGlobalModal>
    </AppWithAuth0>);
};

ReactDOM.render(<DashboardRoot />, document.getElementById("cff-dashboard-root"));
