import React, {FC, useState, useEffect, useRef, useMemo} from "react";
import classNames from "classnames";

import {FilterValue, ToggleOption} from "../../types";
import {FieldHandleAction, Mode} from "../../formBuilder/types";
import {MSLabel} from "../MSLabel";

import "./index.scss";


export interface MSToggleProps {
    options: ToggleOption[];
    title?: string;
    disabled?: boolean;
    value?: string | number;
    handleChange?: (optionData: FilterValue, name: string, isHandleAction: boolean, handleAction: FieldHandleAction) => void;
    isHandleAction?: boolean;
    handleAction?: FieldHandleAction;
    fieldLabel: string;
    fieldId: string;
    isRequired: boolean;
    error?: string;
    name?: string;
    mode?: Mode;
    shouldShowClear?: boolean;
    isPencilDisplayed?: boolean;
    isDisabled?: boolean;
}


export const updateToggleWrapperDirection = (wrapper: HTMLDivElement): void => {
    const wrapperWidth = wrapper.getBoundingClientRect().width;
    const childrenWidth = Array.from(wrapper.children).reduce((width, child) => {
        return width + child.getBoundingClientRect().width;
    }, 0);

    if (childrenWidth > wrapperWidth) {
        wrapper.classList.add("ms-toggle-column");
    }

    wrapper.classList.remove("hidden");

};

export const MSToggle: FC<MSToggleProps> = (props) => {
    const {options, name, value, handleChange, isHandleAction, handleAction, fieldLabel, isRequired, fieldId, mode, error, isPencilDisplayed, isDisabled} = props;
    const [toggleValue, setToggleValue] = useState(value);
    const toggleWrapper = useRef<HTMLDivElement>();

    const activeOptions = useMemo(
        () =>
            options.filter((option) => {
                if (!option.inactive) return true;
                return value === option.value;
            }),
        [options]
    );

    useEffect(() => {
        setToggleValue(value);
    }, [value]);

    useEffect(() => {
        if (toggleWrapper.current) {
            updateToggleWrapperDirection(toggleWrapper.current);
        }
    }, []);

    return (
        <div className="ms-form-group">
            <MSLabel isRequired={isRequired} fieldLabel={fieldLabel} isPencilDisplayed={isPencilDisplayed} />
            <div className={classNames("ms-toggle", {"has-error": error})} ref={toggleWrapper}> {activeOptions.map((item, index) => (
                <div key={index} className="ms-toggle-item">
                    <input
                        disabled={isDisabled || mode === "READ"}
                        type="radio"
                        id={`${fieldId}_${item.value}`}
                        name={fieldId}
                        value={item.value}
                        onChange={(e) => {
                            handleChange && handleChange(e.target.value, name, isHandleAction, handleAction);
                            setToggleValue(e.target.value);
                        }}
                        checked={item.value === toggleValue}
                    />
                    <label htmlFor={`${fieldId}_${item.value}`}>{item.uiLabel}</label>
                </div>

            ))}
            </div>
            {!!error && <div className="error-message">{error}</div>}
        </div>
    );
};
