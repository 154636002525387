import * as Yup from "yup";
import {FC} from "react";
import {FormikValues} from "formik";

import {FormField} from "@medispend/common/src/formBuilder/types";
import {InputMask} from "@medispend/common/src/formBuilder/constantsLib";
import {REQUIRED_TYPES_LIST} from "../EditableScreen/constants";


export interface RequestBodyFilters<F> {
    filters: F,
    searchQuery: string
}

export interface CffScreenFilters {
    clients?: string[] | null,
    processes: string | null,
    types: string | null,
}

export type User = {
    firstName: string;
    lastName: string;
    roles?: string[];
    emUserId?: number;
    username?: string;
    email?: string;
}

export interface PaginationParameters {page?: number, size?: number}

export interface CFFContentItem {
    clientUiLabel: string;
    clientId: string;
    processName: string;
    screenId: string;
    screenName: string;
    type: string;
}

export interface CFFScreenTableInfo {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
}

export interface CFFScreenTableData extends CFFScreenTableInfo {
    content: CFFContentItem[];
}

interface ScreenSection {
    sectionId: string;
    sectionUiLabel: string;
    sectionWidth: number;
    value: string;
    uiLabel: string;
}

export interface ScreenSections {
    sections: ScreenSection[];
    screenName: string;
}

interface ScreenStateInfo {
    fieldIdRegExp: RegExp;
    requestMethod: "post" | "put" | "patch";
    title: string;
    fieldParams: FormField | DefaultValue,
    message: string,
    action: string
}

export interface DefaultValue {
    fieldId: string,
    fieldLabel: string,
    fieldDescription: string,
    fieldType: string
}

export type ScreenStatesInfo = Record<number, ScreenStateInfo>;

export type YupObject = Record<string, Yup.AnySchema>;

export interface TextTypeField {
    inputMask: InputMask,
    inputMaskPattern: string,
    maxFieldLength: number,
    defaultValue: string,
    requiredType: string
}

export interface NumberTypeField {
    decimalPlaces: number,
    maxFieldLength: number,
    defaultValue: string,
    requiredType: string
}

export interface CurrencyTypeField {
    requiredType: REQUIRED_TYPES_LIST.OPTIONAL
}

export interface TextAreaTypeField {
    maxFieldLength: number,
    defaultValue: string,
    requiredType: string
}

export interface DropdownTypeField {
    defaultValue: string,
    requiredType: string,
    dropdown: {values: [], name: string, id: number},
    dropdownId: number
}

export interface ToggleTypeField {
    defaultValue: string,
    requiredType: string,
    toggle: {values: [], name: string, id: number},
    toggleId: number
}

export interface StandardDropdownTypeField {
    requiredType: string,
}

export interface MultiSelectTypeField {
    defaultValue: string[] | number[],
    requiredType: string,
    dropdown: {values: [], name: string},
    dropdownId: number
}

export interface DateTypeField {
    defaultValue: string,
    requiredType: string
}

export interface HyperlinkTypeField {
    defaultValue: string,
    requiredType: string
}

interface TextTypeViewProps {
    field: FormField,
    onEditField: (field: FormField) => void,
    onRemoveField: (field: FormField) => void,
    id: string,
    index?: number,
    moveField: any,
    resizeField: any,
    maxWidth: number,
    isEditable: boolean,
    isActive?: boolean,
    isEmptySpace: boolean
}

interface AdditionalFieldsProps {
    formik: FormikValues,
    handleChangeFieldType: (validationRules: YupObject) => void,
    editableField: FormField
}

export interface FieldTypeComponents {
    [key: string]: {formFields: FC<AdditionalFieldsProps>, fieldView: FC<TextTypeViewProps>, isEditable: boolean, isActive?: boolean}
}

export interface ListItem {
    code: string,
    id?: string,
    fieldId?: string,
    label: string,
    inactive: boolean,
    yPosition: number
}

export interface ListFieldType {
    name: string,
    id?: string,
    values: ListItem[]
}

export interface CurrencyType {
    code: string,
    label: string,
    decimalPlaces: number
}

export interface CurrencyOption {
    value: string,
    uiLabel: string,
    decimalPlaces: number
}

export interface EditorStates {
    [ListTypes.USE_EXISTING]: ListFieldType,
    [ListTypes.CREATE_NEW]: ListFieldType
}

export enum ListTypes {
    USE_EXISTING = "USE_EXISTING",
    CREATE_NEW = "CREATE_NEW",
}
