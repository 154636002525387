import {TextTypeFields} from "./formFields/TextTypeFields";
import {TextAreaTypeFields} from "./formFields/TextAreaTypeFields";
import {DateTypeFields} from "./formFields/DateTypeFields";
import {TextTypeView} from "./formFields/TextTypeView";
import {StandardDropdownBasedTypeFields} from "./formFields/StandardDropdownTypeField";
import {DropdownTypeFields} from "./formFields/DropdownTypeFields";
import {MultiSelectTypeFields} from "./formFields/MultiselectTypeFields";
import {NumberTypeFields} from "./formFields/NumberTypeFields";
import {ToggleTypeFields} from "./formFields/ToggleTypeFields";
import {CurrencyTypeFields} from "./formFields/CurrencyTypeFields";
import {FieldTypeComponents} from "../common/types";
import {HyperlinkTypeFields} from "./formFields/HyperlinkTypeFields";


export enum EDITABLE_SCREEN_STATES {
    EMPTY,
    AVAILABLE_FIELDS,
    EDIT_FIELD,
    CREATE_FIELD
}

export const FIELD_TYPE_COMPONENTS: FieldTypeComponents = {
    TEXT: {formFields: TextTypeFields, fieldView: TextTypeView, isEditable: true},
    TEXT_AREA: {formFields: TextAreaTypeFields, fieldView: TextTypeView, isEditable: true},
    DATE: {formFields: DateTypeFields, fieldView: TextTypeView, isEditable: true},
    CUSTOM_DROPDOWN: {formFields: DropdownTypeFields, fieldView: TextTypeView, isEditable: true},
    STANDARD_DROPDOWN: {formFields: StandardDropdownBasedTypeFields, fieldView: TextTypeView, isEditable: true},
    FILE: {formFields: null, fieldView: TextTypeView, isEditable: false},
    CUSTOM_MULTISELECT: {formFields: MultiSelectTypeFields, fieldView: TextTypeView, isEditable: true},
    STANDARD_MULTISELECT: {formFields: StandardDropdownBasedTypeFields, fieldView: TextTypeView, isEditable: true},
    NUMBER: {formFields: NumberTypeFields, fieldView: TextTypeView, isEditable: true},
    CUSTOM_TOGGLE: {formFields: ToggleTypeFields, fieldView: TextTypeView, isEditable: true},
    STANDARD_TOGGLE: {formFields: StandardDropdownBasedTypeFields, fieldView: TextTypeView, isEditable: true},
    CURRENCY: {formFields: CurrencyTypeFields, fieldView: TextTypeView, isEditable: true},
    HYPERLINK: {formFields: HyperlinkTypeFields, fieldView: TextTypeView, isEditable: true}
};

export const REQUIRED_TYPE = [{uiLabel: "Always", value: "ALWAYS"}, {uiLabel: "Workflow", value: "WORKFLOW"}, {uiLabel: "Optional", value: "OPTIONAL"}, {uiLabel: "Final Approval", value: "FINAL_APPROVAL"}];

export enum REQUIRED_TYPES_LIST {
    ALWAYS = "ALWAYS",
    WORKFLOW = "WORKFLOW",
    OPTIONAL = "OPTIONAL",
    FINAL_APPROVAL = "FINAL_APPROVAL"
}

export const toggleOptions = [{
    uiLabel: "Show",
    value: false
}, {
    uiLabel: "Hide",
    value: true
}];
