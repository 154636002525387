export const padToEnd = (value: string, padString: string, numOfPads: number) => {
    return value.padEnd(value.length + numOfPads, padString);
};

export const padWithZeros = (value: string, numOfPads: number, addDot = false) => {
    if (addDot && numOfPads > 0) {
        value = value + ".";
    }
    return padToEnd(value, "0", numOfPads);
};

const countDecimals = (value: string): number => {
    if (isNaN(Number(value))) {
        return 0;
    }
    let result = 0;
    if (value.split(".").length > 1) {
        result = value.split(".")[1].length;
    }
    return result;
};

export const adjustDecimals = (value: string, decLimit: number): string => {
    const currentLimit = countDecimals(value);
    const hasDot = value.includes(".");
    if (decLimit === 0) {
        return hasDot ? value.substring(0, value.indexOf(".")) : value;
    } else if (decLimit > currentLimit) {
        return padWithZeros(value, decLimit - currentLimit, !hasDot);
    } else if (decLimit < currentLimit) {
        return value.substring(0, value.length - (currentLimit - decLimit));
    } else {
        return value;
    }
};
