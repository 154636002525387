import React, {FC} from "react";
import {FormikValues} from "formik";
import * as Yup from "yup";
import * as _ from "lodash";

import {MSFormSelect} from "@medispend/common/src/components/MSFormSelect";
import {ButtonColors} from "@medispend/common/src/components/MSButton/types";
import {getRequiredMessage} from "@medispend/common/src/constants";
import {FormField} from "@medispend/common/src/formBuilder/types";
import {REQUIRED_TYPE} from "../constants";
import {ListFieldType, ToggleTypeField, YupObject} from "../../common/types";
import {useRegisterFields} from "../hooks/useRegisterFields";
import {useGetToggles} from "../hooks/useGetToggles";
import ListWrapper from "./typeWithItems/ListWrapper";
import {getListOptions} from "./typeWithItems/helpers";


interface ToggleTypeFieldsProps {
    formik: FormikValues,
    handleChangeFieldType: (validationRules: YupObject) => void,
    editableField: FormField,
    toggleId: null
}

export const ToggleTypeFields: FC<ToggleTypeFieldsProps> = ({formik, handleChangeFieldType, editableField}) => {
    const {errors, touched} = formik;

    const initialValues: ToggleTypeField = {
        toggle: {values: [], name: "", id: null},
        defaultValue: "",
        requiredType: "OPTIONAL",
        toggleId: formik.values?.toggle?.id || null
    };

    const refreshToggles = useGetToggles();

    const editableFieldValues = _.pick(editableField, ["toggle", "defaultValue", "requiredType", "toggleId"]);
    const validationSchema = {
        defaultValue: Yup.string().nullable().required(getRequiredMessage("Default Value")),
        requiredType: Yup.mixed()
            .required(getRequiredMessage("Required Type")),
        toggle: Yup.object().shape({
            values: Yup.array().min(1, getRequiredMessage("Toggle Values"))
        })
    };
    const fieldsList = ["toggle", "defaultValue", "requiredType", "toggleId"];
    useRegisterFields(handleChangeFieldType, validationSchema, initialValues, (editableFieldValues as ToggleTypeField), fieldsList, formik);

    const onListWrapperChange = (savedToggle: ListFieldType) => {
        formik.setFieldValue("toggle", savedToggle);
        formik.setFieldValue("toggleId", savedToggle.id);
        const activeToggleValues = savedToggle.values.filter(value => !value.inactive);
        const currDefValue: string | null = formik.values.defaultValue;
        if (// Toggle 100% should have active values but still worth to check
            activeToggleValues.length
            && (!currDefValue
                //is current default value is not valid
                || activeToggleValues.findIndex(value => value.code === currDefValue) === -1)) {
            formik.setFieldValue("defaultValue", activeToggleValues[0].code);
        }
    };


    return (<>
        <MSFormSelect
            name="requiredType"
            isDisabled={false}
            activeVariant={null}
            options={REQUIRED_TYPE}
            fieldLabel="Required Field"
            placeholder="Select"
            value={formik.values.requiredType}
            isRequired={false}
            error={(touched.requiredType && errors.requiredType) || ""}
            placeVariant="grid"
            size="md"
            handleChange={(value) => formik.setFieldValue("requiredType", value)}
            variant={ButtonColors.white}
        />
        <ListWrapper
            name="toggle"
            fieldLabel="Toggle Values"
            isRequired={false}
            isDisabled={false}
            error={(touched.toggle?.values && errors.toggle?.values) || ""}
            list={formik.values.toggle || initialValues.toggle}
            handleChange={onListWrapperChange}
            refreshEntities={refreshToggles}
            listName="toggle"
            maxListLength={3}
            minListLength={2}
        />
        <MSFormSelect
            name="defaultValue"
            isDisabled={!formik.values?.toggle?.values?.length}
            activeVariant={null}
            options={getListOptions(formik.values?.toggle?.values || [])}
            fieldLabel="Default Value"
            placeholder="Select default value"
            value={formik.values.defaultValue}
            isRequired={false}
            error={(touched.defaultValue && errors.defaultValue) || ""}
            placeVariant="grid"
            size="md"
            handleChange={(value) => formik.setFieldValue("defaultValue", value)}
            variant={ButtonColors.white}
        />
    </>);
};
