import React, {ReactElement} from "react";
import classNames from "classnames";

import {ListItem} from "../../../common/types";
import {useDragAndDrop} from "@medispend/common/hooks/useDragAndDrop";
import {FormField} from "@medispend/common/src/formBuilder/types";


interface ItemViewProps {
    field: ListItem,
    onEditField?: (field: ListItem) => void
    id: string,
    index: number,
    moveField: (dragIndex: FormField, hoverIndex: FormField) => void,
    editable?: boolean
}

export const ItemView = ({field, onEditField, moveField, editable}: ItemViewProps): ReactElement<ItemViewProps> => {
    const {opacity, ref, drag, drop} = useDragAndDrop(moveField, field);
    drag(drop(ref));
    return <div className={classNames("section-field", {"is-editable": editable})} key={field.code} ref={ref} style={{opacity}} onClick={() => onEditField(field)}>
        <span className="section-field__label">{field.code} - {field.label}</span>
    </div>;
};
