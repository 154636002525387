import React, {FC, useCallback, useEffect, useState} from "react";
import {Scrollbars} from "react-custom-scrollbars-2";
import {useParams} from "react-router-dom";
import * as _ from "lodash";

import {ButtonColors, ButtonSizes} from "@medispend/common/src/components/MSButton/types";
import {MSButton} from "@medispend/common/src/components/MSButton";
import {FilterValue, ResponseState} from "@medispend/common/src/types";
import {FieldValue, FormField, SectionInfo} from "@medispend/common/src/formBuilder/types";
import {MSLoader} from "@medispend/common/src/components/MSLoader";
import {useQuery} from "@medispend/common/src/components/MSLayout";
import {sortDesc, sortFormFiledByYPos} from "@medispend/common/src/utils/sort";
import {PageLeavingGuard} from "@medispend/common/src/components/PageLeavingGuard";
import notification, {MessagesType} from "@medispend/common/src/components/MSNotifications";
import {ExistingListsContext, useExistingListsContext} from "../context/ExistingListsContext";
import {ListFieldType} from "../common/types";
import {createUpdateAjax, getAjax} from "../services/ajax";
import {EDITABLE_SCREEN_STATES} from "./constants";
import {AvailableFieldsState} from "./AvailableFieldsState";
import {CreateUpdateFieldState} from "./CreateUpdateFieldState";
import {SectionView} from "./SectionView";
import {NotEditableField} from "./NotEditableField";


interface EditableScreenParams {
    screenId: string;
}

export const EditableScreen: FC = () => {
    const [screenState, setScreenState] = useState(EDITABLE_SCREEN_STATES.EMPTY);
    const [screenInfo, setScreenInfo] = useState(null);
    const [defaultSectionInfo, setDefaultSectionInfo] = useState(null);
    const [sectionInfo, setSectionInfo] = useState(null);
    const [intermediateSectionData, setIntermediateSectionData] = useState(null);
    const [editableField, setEditableField] = useState<any>({});
    const [showModal, setShowModal] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);

    const {screenId} = useParams<EditableScreenParams>();
    const query = useQuery();
    const [isLoading, setIsLoading] = useState(false);

    const clientId = query.get("clientId");
    const clientInfo = clientId ? `?clientId=${clientId}` : "";

    const {dropdowns, toggles} = useExistingListsContext();
    const [existingDropdowns, setExistingDropdowns] = useState(dropdowns);
    const [existingToggles, setExistingToggles] = useState(toggles);

    useEffect(() => {
        if (screenInfo?.sections.length === 1) {
            setScreenState(EDITABLE_SCREEN_STATES.AVAILABLE_FIELDS);
            updateSectionInfo(screenInfo?.sections[0], hasChanges);
        }
    }, [screenInfo?.sections]);

    const updateSectionWithList = (list: ListFieldType[], listName: "dropdown" | "toggle") => {
        if (list.length) {
            const updatedSectionInfo = {...sectionInfo};
            updatedSectionInfo.fields.forEach((field: FormField) => {
                if (field[listName]) {
                    field[listName] = list.find((item: any) => item.id === field[listName].id);
                }
            });
            setSectionInfo(_.cloneDeep(updatedSectionInfo));
        }
    };

    useEffect(() => {
        setIsLoading(true);
        getAjax(`/api/screen/${screenId}/${clientInfo}`).subscribe((response: ResponseState) => {
            const sections = response.data.sections.map((section: SectionInfo) => {
                section.uiLabel = `${section.sectionUiLabel}`;
                section.value = `${section.sectionId}`;
                return section;
            });
            setScreenInfo({...response.data, sections});
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenId]);

    const createNewField = useCallback(() => {
        setEditableField(null);
        setScreenState(EDITABLE_SCREEN_STATES.CREATE_FIELD);
    }, []);

    const getNewSectionInfo = useCallback((field?: FormField) => {
        const updatedFieldIndex = sectionInfo.fields.findIndex((sectionField: FormField) => sectionField.fieldId === field.fieldId);
        sectionInfo.fields[updatedFieldIndex] = {...sectionInfo.fields[updatedFieldIndex], ...field};
        return _.cloneDeep(sectionInfo);
    }, [sectionInfo]);

    const handleChangeSectionInfo = (newSectionInfo: SectionInfo) => {
        const fieldsForComparison = ["xPosition", "yPosition", "isHidden", "fieldSize", "fieldId"];
        screenState !== EDITABLE_SCREEN_STATES.CREATE_FIELD && setHasChanges(!_.isEqual(
            {...newSectionInfo, fields: newSectionInfo.fields.map(field => _.pick(field, fieldsForComparison))},
            {
                ...defaultSectionInfo,
                fields: defaultSectionInfo.fields.map((field: FieldValue) => _.pick(field, fieldsForComparison))
            }
        ));
        setSectionInfo(newSectionInfo);
    };

    const updateSectionInfo = (newSectionInfo: SectionInfo, hasChanges: boolean) => {
        setIntermediateSectionData(newSectionInfo);
        if (hasChanges) {
            setShowModal(true);
        } else {
            setIsLoading(true);
            getAjax(`/api/section/${newSectionInfo.sectionId}${clientInfo}`).subscribe((response: ResponseState) => {
                const updatedSectionInfo = {
                    ...response.data, uiLabel: `${response.data.sectionUiLabel}`,
                    value: response.data.sectionId
                };
                updatedSectionInfo.fields.forEach((field: FormField, index: number) => {
                    if (field.toggle) {
                        updatedSectionInfo.fields[index] = {...field, toggleId: field.toggle.id};
                    }
                    if (field.dropdown) {
                        updatedSectionInfo.fields[index] = {...field, dropdownId: field.dropdown.id};
                    }
                });
                setDefaultSectionInfo(_.cloneDeep(updatedSectionInfo));
                setSectionInfo(updatedSectionInfo);
                setIsLoading(false);
            });
        }
    };

    const showAvailableFields = useCallback((field?: FormField, action?: string) => {
        let newSectionInfo: SectionInfo = null;
        setScreenState(EDITABLE_SCREEN_STATES.AVAILABLE_FIELDS);
        if (field && action === "create") {
            newSectionInfo = {...sectionInfo, fields: [...sectionInfo.fields, field]};
            setDefaultSectionInfo(_.cloneDeep(newSectionInfo));
        }
        if (field && action === "update") {
            newSectionInfo = getNewSectionInfo(field);
        }
        if (newSectionInfo) {
            handleChangeSectionInfo(newSectionInfo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionInfo, screenState, setScreenState]);

    const onSectionChange = useCallback((value: FilterValue) => {
        if (value) {
            setScreenState(EDITABLE_SCREEN_STATES.AVAILABLE_FIELDS);
            updateSectionInfo(screenInfo?.sections.find((section: SectionInfo) => section.value === value), hasChanges);
        }
    }, [updateSectionInfo, screenInfo?.sections, hasChanges]);

    const onEditField = useCallback((field: FormField) => {
        setEditableField(field);
        setScreenState(EDITABLE_SCREEN_STATES.EDIT_FIELD);
    }, []);

    const onChangeSectionInfo = useCallback((field: FormField) => {
        const newSectionInfo = getNewSectionInfo(field);
        handleChangeSectionInfo(newSectionInfo);
    }, [getNewSectionInfo]);

    const handleScreenSave = () => {
        setIsLoading(true);
        const sectionFields = sectionInfo.fields.filter((field: FormField) => !field.isHidden).sort(sortFormFiledByYPos);
        const yPosListSet = new Set(sectionFields.map((field: FormField) => field.yPosition));
        const yPosList = Array.from(yPosListSet);
        yPosList.forEach((item, index) => {
            sectionFields.forEach((field: FormField, sectionFieldIndex: number) => {
                if (field.yPosition === item) {
                    sectionFields[sectionFieldIndex].yPosition = index + 1;
                }
            });
        });
        const hiddenFields = sectionInfo.fields.filter((field: FormField) => field.isHidden);
        createUpdateAjax("put", `api/section/${sectionInfo.sectionId}${clientInfo}`, {
            fields: [...(sectionFields.filter((field: FormField) => field.fieldType)), ...hiddenFields],
            isHidden: sectionInfo.isHidden
        }, false).subscribe((response: ResponseState) => {
            if (response.error) {
                notification(response.error?.response.message, MessagesType.ERROR);
            } else if (response.data) {
                setHasChanges(false);
                const updatedSectionInfo = {...sectionInfo, ...response.data};
                setDefaultSectionInfo(_.cloneDeep(updatedSectionInfo));
                setSectionInfo(updatedSectionInfo);
                notification("Screen was saved successfully", MessagesType.SUCCESS);
            }
            setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        });
    };

    const handleApproveLeavingPage = () => {
        setHasChanges(false);
        setShowModal(false);
        updateSectionInfo(intermediateSectionData, false);
    };

    if (!screenInfo) {
        return <MSLoader />;
    }
    return (<div>
        <PageLeavingGuard
            hasChanges={hasChanges}
            showModal={showModal}
            setShowModal={setShowModal}
            handleApproveLeavingPage={handleApproveLeavingPage}
        />
        <div className="header-wrapper">
            <h1 className="header">Custom Form Fields</h1>
            {hasChanges && screenState === EDITABLE_SCREEN_STATES.AVAILABLE_FIELDS && (
                <MSButton
                    size={ButtonSizes.md}
                    variant={ButtonColors.green}
                    onClick={handleScreenSave}>
                    <div className="ms-button-title">
                        Save
                    </div>
                </MSButton>
            )}
        </div>
        <Scrollbars
            renderView={props => (
                <div {...props} style={{...props.style, position: "relative", paddingBottom: "20px"}} />
            )}
        >
            <div className="editable-screen-content">
                <div className="section-view">
                    <SectionView
                        screenInfo={screenInfo}
                        sectionInfo={sectionInfo}
                        onSectionChange={onSectionChange}
                        onEditField={onEditField}
                        editableField={editableField}
                        onChangeSectionInfo={onChangeSectionInfo}
                        handleChangeSectionInfo={handleChangeSectionInfo}
                    />
                </div>
                <div className="section-details">
                    {screenState === EDITABLE_SCREEN_STATES.AVAILABLE_FIELDS &&
                <AvailableFieldsState
                    onChangeSectionInfo={onChangeSectionInfo}
                    availableFields={sectionInfo?.fields?.filter((field: FormField) => field.isHidden)}
                    handleCreateField={createNewField}
                    maxYPosition={sectionInfo?.fields?.filter((field: FormField) => !!field.yPosition).map((field: FormField) => field.yPosition).sort(sortDesc)[0]}
                />
                    }
                    {(screenState === EDITABLE_SCREEN_STATES.CREATE_FIELD || screenState === EDITABLE_SCREEN_STATES.EDIT_FIELD)
                &&
                <ExistingListsContext.Provider value={{
                    dropdowns: existingDropdowns,
                    setExistingDropdowns,
                    toggles: existingToggles,
                    setExistingToggles
                }}>
                    {(!editableField || (!_.has(editableField, "isPropsEditable") || editableField?.isPropsEditable)) ? (
                        <CreateUpdateFieldState
                            sectionInfo={sectionInfo}
                            showAvailableFields={showAvailableFields}
                            screenState={screenState}
                            editableField={editableField}
                            updateSectionWithList={updateSectionWithList}
                        />) : (<NotEditableField showAvailableFields={showAvailableFields} />)
                    }
                </ExistingListsContext.Provider>
                    }
                </div>
            </div>
        </Scrollbars>
        {isLoading && <MSLoader />}
    </div>);
};
