import React, {FC} from "react";

import {ButtonColors, ButtonSizes} from "@medispend/common/src/components/MSButton/types";
import {MSButton} from "@medispend/common/src/components/MSButton";
import {FormField} from "@medispend/common/src/formBuilder/types";


export const NotEditableField: FC<{showAvailableFields: (field?: FormField, action?: string) => void} > = ({showAvailableFields}) => {
    return (
        <div>
            <h2>Field is not editable</h2>
            <MSButton
                size={ButtonSizes.sm}
                variant={ButtonColors.grey100}
                onClick={() => showAvailableFields()}
            >
                Cancel
            </MSButton>
        </div>
    );
};
