import {useEffect} from "react";
import {FormikValues} from "formik";

import {TextAreaTypeField, TextTypeField, DateTypeField, DropdownTypeField, YupObject, MultiSelectTypeField, StandardDropdownTypeField} from "../../common/types";


type ValuesTypes = TextAreaTypeField | TextTypeField | DateTypeField | DropdownTypeField | MultiSelectTypeField | StandardDropdownTypeField;

export const useRegisterFields = (handleChangeFieldType: (validationRules: YupObject) => void, validationSchema: any, initialValues: ValuesTypes, updatedValues: ValuesTypes, fieldsList: string[], formik: FormikValues): void => {
    useEffect(() => {
        const values = {...initialValues, ...updatedValues};
        fieldsList.forEach((field: keyof typeof initialValues) => formik.setFieldValue(`${field}`, values[field]));
        handleChangeFieldType(validationSchema);
        return () => {
            fieldsList.forEach((field: string) => {
                formik.unregisterField(`${field}`);
                formik.setFieldValue(`${field}`, undefined);
                formik.setFieldTouched(`${field}`, false);
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
