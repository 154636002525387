import React, {FC} from "react";
import {FormikValues} from "formik";
import * as Yup from "yup";
import * as _ from "lodash";

import {MSFormSelect} from "@medispend/common/src/components/MSFormSelect";
import {ButtonColors} from "@medispend/common/src/components/MSButton/types";
import {getRequiredMessage} from "@medispend/common/src/constants";
import {REQUIRED_TYPE} from "../constants";
import {FormField} from "@medispend/common/src/formBuilder/types";
import {DateTypeField, YupObject} from "../../common/types";
import {useRegisterFields} from "../hooks/useRegisterFields";
import {MSDatepicker} from "@medispend/common/src/components/MSDatepicker";
import {clearTime, getDate} from "@medispend/common/src/utils";


interface DateTypeFieldsProps {
    formik: FormikValues,
    handleChangeFieldType: (validationRules: YupObject) => void,
    editableField: FormField
}

export const DateTypeFields: FC<DateTypeFieldsProps> = ({formik, handleChangeFieldType, editableField}) => {
    const {errors, touched, values} = formik;
    const initialValues: DateTypeField = {
        defaultValue: "",
        requiredType: "OPTIONAL"
    };
    const fieldsList = ["defaultValue", "requiredType"];
    const editableFieldValues = _.pick(editableField, fieldsList);
    const validationSchema = {
        requiredType: Yup.mixed()
            .required(getRequiredMessage("Required Type"))
    };
    useRegisterFields(handleChangeFieldType, validationSchema, initialValues, (editableFieldValues as DateTypeField), fieldsList, formik);

    const defaultDateValue: number = formik.values.defaultValue;
    return (<>
        <MSDatepicker onChange={(value) => {
            formik.setFieldValue("defaultValue", getDate(value));
        }}
        name="defaultValue"
        error={(touched.defaultValue && errors.defaultValue) || ""}
        options={{value: defaultDateValue ? clearTime(new Date(defaultDateValue)).getTime() : null, name: "defaultValue"}}
        label="Default Value"
        isDropdown
        placeholder="/ /"
        startLimit={null} endLimit={null} />
        <MSFormSelect
            name="requiredType"
            isDisabled={false}
            activeVariant={null}
            options={REQUIRED_TYPE}
            fieldLabel="Required Field"
            placeholder="Select"
            value={values.requiredType}
            isRequired={false}
            error={(touched.requiredType && errors.requiredType) || ""}
            placeVariant="grid"
            size="md"
            handleChange={(value) => formik.setFieldValue("requiredType", value)}
            variant={ButtonColors.white}
        />
    </>);
};
