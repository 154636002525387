import React, {FC} from "react";
import {FormikValues} from "formik";
import * as Yup from "yup";
import * as _ from "lodash";

import {MSFormSelect} from "@medispend/common/src/components/MSFormSelect";
import {ButtonColors} from "@medispend/common/src/components/MSButton/types";
import {
    getRequiredMessage
} from "@medispend/common/src/constants";
import {FormField} from "@medispend/common/src/formBuilder/types";
import {CurrencyTypeField, TextTypeField, YupObject} from "../../common/types";
import {useRegisterFields} from "../hooks/useRegisterFields";
import {REQUIRED_TYPE, REQUIRED_TYPES_LIST} from "../constants";


interface CurrencyTypeFieldsProps {
    formik: FormikValues,
    handleChangeFieldType: (validationRules: YupObject) => void,
    editableField: FormField
}

export const CurrencyTypeFields: FC<CurrencyTypeFieldsProps> = ({formik, handleChangeFieldType, editableField}) => {
    const {errors, touched, values} = formik;
    const initialValues: CurrencyTypeField = {
        requiredType: REQUIRED_TYPES_LIST.OPTIONAL
    };
    const editableFieldValues = _.pick(editableField, ["defaultValue", "requiredType"]);
    const validationSchema = {
        requiredType: Yup.mixed()
            .required(getRequiredMessage("Required Type"))
    };
    const fieldsList = ["defaultValue", "requiredType"];

    useRegisterFields(handleChangeFieldType, validationSchema, initialValues, (editableFieldValues as TextTypeField), fieldsList, formik);

    return (<>
        <MSFormSelect
            name="requiredType"
            isDisabled={false}
            activeVariant={null}
            options={REQUIRED_TYPE}
            fieldLabel="Required Field"
            placeholder="Select"
            value={values.requiredType}
            isRequired={false}
            error={(touched.requiredType && errors.requiredType) || ""}
            placeVariant="grid"
            size="md"
            handleChange={(value) => formik.setFieldValue("requiredType", value)}
            variant={ButtonColors.white}
        />
    </>);
};
