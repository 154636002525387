import {FunctionComponent} from "react";
import {ComponentsTypes, InputMask} from "./constantsLib";
import {BUTTONS_TYPES} from "./buttons";
import {SelectOption} from "../components/MSSelect/BaseSelect/types";
import {DatepickerOptions} from "../types";
import {MSAlertMessage} from "../components/MSAlertMessage";


export interface FieldHandleAction {
    actionUrl: string;
    paramsFieldIds: string[]
}

export type FormFieldHint = {
    type: "warning" | "error" | "info",
    text: string;
    hideOnFieldChange?: boolean;
}

export interface FormField {
    defaultCurrency?: string;
    decimalPlaces?: number;
    dropdown?: any;
    toggle?: any;
    currency?: string;
    dropdownId?: number;
    toggleId?: number;
    button?: Button;
    isPropsEditable?: boolean;
    fieldId: string,
    parentFieldId: string,
    fieldType: ComponentsTypes,
    name?: string,
    options?: SelectOption[] | DatepickerOptions,
    value: string | number | number[],
    brValue?: string | number,
    fieldLabel: string,
    displayValue?: string,
    updatedDisplayValue?: string,
    fieldDescription: string,
    process?: string,
    screen?: string,
    section?: string,
    sectionUiLabel?: string,
    sectionColumnsNumber?: number,
    fieldSize: number,
    xPosition: number,
    yPosition: number,
    defaultValue: string | number | number[],
    isDisabled: boolean,
    isHidden: boolean,
    isStandard: boolean,
    isReadOnly: boolean,
    inputMask?: InputMask,
    inputMaskPattern?: string,
    toolTip?: string,
    fieldLength?: number,
    isRequired: boolean,
    requiredType: string,
    isHandleAction?: boolean,
    handleAction?: FieldHandleAction,
    maxFieldLength: number,
    maxNumberLength?: number,
    isHideable: boolean,
    // BR names
    usedInBizRules: string[],
    isAsteriskDisplayed?: boolean,
    hasDynamicFields?: boolean,
    maxFileSizeMb?: number,
    startLimit?: number | string,
    endLimit ?: number | string,
    // Selecting the date outside the soft limit is possible but the warning message will be shown
    startSoftLimit ?: number | string,
    endSoftLimit ?: number | string,
    startSoftLimitWarnMsg ?: number | string,
    endSoftLimitWarnMsg ?: number | string,
    hint?: FormFieldHint
}

export interface FormEmptyField {
    xPosition: number,
    yPosition: number,
    fieldId: string
}

export interface SectionInfo {
    fields: FormField[] | FormEmptyField[],
    process: string,
    screen: string,
    sectionUiLabel: string,
    sectionId: string,
    uiLabel: string,
    value: string,
    sectionWidth: number,
    isHidden: boolean,
    isHideable: boolean,
    uniqueName: string,
}

type ActionHandler<T> = (arg?: T) => void;

export enum DisplayType {
    TABLE_ROW = "TABLE_ROW",
    TABLE_ROW_OUTSIDE = `TABLE_ROW_OUTSIDE`,
}

export interface Button {
    buttonType: BUTTONS_TYPES,
    fieldId?: string,
    isDisabled?: boolean,
    isHidden?: boolean,
    displayType?: string,
    sectionId?: string,
    actionHandler: ActionHandler<any>,
    uiLabel: string,
    component: FunctionComponent<any>
    parentFieldId?: string;
}

export type FieldValue = boolean | string | number | number[] | string[];

export interface InitialValues {
    [key: string]: {[key: string]: string | number | number[] | File}
}

export enum Mode {
    EDIT= "EDIT",
    READ= "READ",
    CREATE= "CREATE",
}

export enum ENTITY_DISPLAY_TYPE {
    TABLE = "TABLE",
    FORM = "FORM"
}

export enum TableCellRawValueType {
    TEXT = "TEXT",
    NUMBER = "NUMBER",
    DATE = "DATE",
    DATE_TIME = "DATE_TIME",
    CURRENCY = "CURRENCY",
    HYPERLINK = "HYPERLINK",
    FILE_HYPERLINK = "FILE_HYPERLINK",
    EXPANDABLE_TEXT = "EXPANDABLE_TEXT"
}

export interface TableCell {
    fieldId?: string,
    value: string,
    rawValue: string,
    hyperlink?: string,
    defaultVisibleSymbolAmount?: number;
    rawValueType?: TableCellRawValueType,
    alertMessage?: string
}

export interface TableRow {
    cells: TableCell[],
    buttonsData: Button[]
    hyperlink: string,
    rawValueType: string
}

export interface TableColumn {
    displayType: ENTITY_DISPLAY_TYPE,
    columnWidth: string,
    sections: [
        {
            sectionId: string,
            sectionUiLabel: string,
            sectionWidth: number,
            uniqueId: string,
            isHidden: boolean,
            isDisabled: boolean,
            headers:{fieldId : string, label: string}[],
            data: {
                [key: number] : TableRow
            }
        }
    ]
}

export interface TableScreen {
    screenName: string,
    displayType: ENTITY_DISPLAY_TYPE.TABLE,
    columns: TableColumn[],
    buttonsData: Record<Mode, Button[]>,
}

export interface FormScreen {
    screenName: string;
    displayType: ENTITY_DISPLAY_TYPE.FORM;
    columns: Column[];
    buttonsData: Record<Mode, Button[]>;
}

export type EntityScreen = FormScreen | TableScreen;

export interface TableState {
    screens: TableScreen[],
    entity: {
        id: number
        mode: Mode
        proxyId: number
        status: string
    }
}

export interface EntityResponseData<Entity extends BaseEntity> {
    screenName: string;
    buttonsData: Record<Mode, Button[]>;
    columns: Column[];
    messages?: MSAlertMessage[];
    entity: Entity;
}

export interface BaseEntity {
    mode: Mode;
    isDisabled: boolean;
    id: string;
    proxyId?: string;
    status: string;
}

export interface CffFormResponse<Entity> {
    entity: Entity;
    screens: EntityScreen[];
    messages?: MSAlertMessage[]
}

export type EntityData<Entity extends BaseEntity> = Omit<EntityResponseData<Entity>, "buttonsData"> & {
    buttonsData: Button[]
}

export interface ResponseData<Entity extends BaseEntity> {
    isLoading?: boolean,
    data?: EntityResponseData<Entity>,
    originalResponse?: any
}

export interface EntityState<Entity extends BaseEntity> {
    isLoading?: boolean,
    data?: EntityData<Entity>,
    originalResponse?: any
}

export interface Section {
    sectionWidth: number;
    sectionId: string;
    columnsNumber: number,
    fields: FormField[],
    sectionName: string,
    sectionUiLabel: string,
    uniqueId: string,
    uniqueName: string,
    isHidden: boolean,
    isDisabled: boolean,
    hasError?: boolean
}

export interface SectionStatic {
    sectionWidth: number;
    render: (props: SectionStaticRenderProps) => React.ReactElement;
}

interface SectionStaticRenderProps {
    formik: any;
}

export interface Column {
    static?: boolean;
    columnWidth: string;
    sections: Array<Section | SectionStatic>
}

export interface DropdownItem {
    code: string,
    id?: string,
    label: string,
    inactive: boolean,
    yPosition: number
}

export interface SaveEntityField {
    fieldId: string,
    type: ComponentsTypes,
    value: string | number | number[],
    isHidden: boolean
}
