import React, {FC} from "react";
import {FormikValues} from "formik";

import {FIELD_TYPE_COMPONENTS} from "../constants";
import {FormField} from "@medispend/common/src/formBuilder/types";
import {YupObject} from "../../common/types";


interface AdditionalFieldsProps {
    formik: FormikValues,
    fieldType: keyof typeof FIELD_TYPE_COMPONENTS | undefined,
    handleChangeFieldType: (validationRules: YupObject) => void,
    editableField: FormField
}

export const AdditionalFieldsWrapper: FC<AdditionalFieldsProps> = ({formik, fieldType, handleChangeFieldType, editableField}) => {
    const FieldTypeComponent = fieldType && FIELD_TYPE_COMPONENTS[fieldType].formFields;
    return (<>
        {(fieldType && FIELD_TYPE_COMPONENTS[fieldType]) ? <FieldTypeComponent formik={formik} handleChangeFieldType={handleChangeFieldType} editableField={editableField} /> : null}
    </>);
};
