import {Observable, mergeMap, of} from "rxjs";

import {Ajax} from "@medispend/common/src/core/http";
import {ResponseState} from "@medispend/common/src/types";
import {notifyResponseError} from "@medispend/common/src/components/MSNotifications";
import {store} from "@medispend/admin-common/src/state/store";
import {isProductionMode, LOCAL_CFF_URL} from "@medispend/admin-common/src/constants";
import {getBaseUrl} from "@medispend/admin-common/src/env";


const withNotification = (response: Observable<ResponseState>, showNotification: boolean, message?: string) => {
    return response.pipe(
        mergeMap((response: ResponseState) => {
            if (response.error?.status === 401) {
                store.getState().authentication.logOutHandler();
            }
            if (showNotification && response.error) {
                notifyResponseError(response, message)
            }
            return of(response);
        }));
};


/**
 * Create Ajax object
 *
 *  @param ajaxOptions (headerAuthKey, getUser)
 *
 *  @returns object for requests running
 */
export const {getAjax, deleteAjax, createUpdateAjax} = new Ajax({baseUrl: isProductionMode && getBaseUrl("cff"), headerAuthKey: "Authorization",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getUser: (): Observable<any> => {
        return of({token: sessionStorage.getItem("ACCESS_TOKEN")});
    }}, withNotification);
