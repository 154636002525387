import {useEffect} from "react";

import {useQuery} from "@medispend/common/src/components/MSLayout";
import {ResponseState} from "@medispend/common/src/types";
import {useExistingListsContext} from "../../context/ExistingListsContext";
import {getAjax} from "../../services/ajax";


export const useGetDropdowns = () => {
    const {dropdowns} = useExistingListsContext();
    const query = useQuery();
    const {setExistingDropdowns} = useExistingListsContext();

    const refreshEntities = () => {
        const clientId = query.get("clientId");
        const clientInfo = clientId ? `?clientId=${clientId}` : "";
        getAjax(`/api/dropdown${clientInfo}`).subscribe((response: ResponseState) => {
            setExistingDropdowns(response.data);
        });
    };

    useEffect(() => {
        !dropdowns.length && refreshEntities();
    }, []);

    return refreshEntities;
};
