import React from "react";
import * as _ from "lodash";

import "./index.scss";

export enum MSAlertMessageStyle {
    REGULAR = "REGULAR",
    SOFT = "SOFT",
}

export interface MSAlertMessage {
    message: string
    style: MSAlertMessageStyle
}

export interface MSAlertMessageProps {
    messages: MSAlertMessage[]
}

export const MSAlertMessages: React.FC<MSAlertMessageProps> = (props) => {
    if (_.isEmpty(props.messages)) {
        return null;
    }

    return <div className="ms-alert-messages">
        {props.messages.map((message, index) =>
            <p className={`ms-alert-message-${message.style === MSAlertMessageStyle.REGULAR ? "regular" : "soft"}`} key={index}>
                {message.message}
            </p>
        )}
    </div>;
};
