import React, {useState, useEffect, FC} from "react";
import classNames from "classnames";

import {ComponentsTypes} from "@medispend/common/src/formBuilder/constantsLib";
import {MSIcon} from "@medispend/common/src/components/MSIcon";
import {sortFormFiledByYPos} from "@medispend/common/src/utils/sort";
import {ListFieldType, ListTypes} from "../../../common/types";
import {ListEditor} from "./ListEditor";

import "./scss/index.scss";


interface ListProps {
    list: ListFieldType;
    fieldLabel: string;
    isRequired: boolean;
    isDisabled: boolean;
    error: string;
    inputType?: ComponentsTypes;
    name?: string;
    handleChange?: (values: ListFieldType) => void;
    refreshEntities?: () => void;
    listName: "dropdown" | "toggle";
    minListLength: number;
    maxListLength?: number;
}

const ListWrapper: FC<ListProps> = ({list, fieldLabel, isRequired, error, handleChange, refreshEntities, listName, minListLength, maxListLength}) => {
    const [fields, setFields] = useState([]);
    const [show, setIsShow] = useState(false);
    const [listType, setListType] = useState(list.id ? ListTypes.USE_EXISTING : ListTypes.CREATE_NEW);

    useEffect(() => {
        setFields(list.values || []);
    }, [list]);

    const handleApprove = (list: ListFieldType) => {
        setFields(list.values);
        handleChange(list);
        setIsShow(false);
    };

    const handleCancel = () => {
        refreshEntities();
        setIsShow(false);
    };

    const onChange = (value: ListTypes) => {
        setListType(value);
    };

    return (
        <>
            <label className="form-label">{fieldLabel}{isRequired && <span className="required-sign">*</span>}</label>
            <div className={classNames({"has-error": error.length})}>
                <div className="add-values">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            setIsShow(true);
                        }}
                        data-testid="add-button"
                    >
                        <MSIcon icon="plus-circle" />
                    </button>
                    Add/Edit Values
                </div>
                <div className="section-fields-container">
                    <div className="list-options">
                        {fields.filter((field) => !field.inactive).sort(sortFormFiledByYPos).map((field) => {
                            return (
                                <div className="section-field-read-mode" key={field.code}>
                                    {`${field.code} - ${field.label}`}
                                </div>
                            );
                        })}
                        {!!fields.filter((field) => field.inactive).length && (
                            <div className="inactive-fields">
                                {fields.filter((field) => field.inactive).map((field) => {
                                    return (<div className="section-field-read-mode" key={field.code}>
                                        {`${field.code} - ${field.label}`}
                                    </div>);
                                })}
                            </div>
                        )}
                    </div>
                </div>
                {!!error.length && <div className="error-message">{error}</div>}
            </div>
            {show && (
                <ListEditor
                    show={show}
                    handleCancel={handleCancel}
                    handleApprove={handleApprove}
                    onChange={onChange}
                    list={list}
                    listType={listType}
                    listName={listName}
                    maxListLength={maxListLength}
                    minListLength={minListLength}
                />
            )}
        </>);
};

export default ListWrapper;
