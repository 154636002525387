import React, {FC, ChangeEvent, useState, useEffect} from "react";

import "./index.scss";


export interface MSRadioButtonProps {
    name: string;
    value: string | number;
    items: Array<{
        title: string;
        value: string | number;
    }>
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

export const MSRadioButton: FC<MSRadioButtonProps> = (props) => {
    const {items, name, value, onChange, disabled} = props;
    const [radioButtonValue, setRadioButtonValue] = useState(value);
    useEffect(() => {
        setRadioButtonValue(value);
    }, [value]);
    return (
        <div className="ms-radio-button">
            {items.map((item) => (
                <div key={item.value} className="ms-radio-button-item">
                    <input
                        disabled={disabled}
                        type="radio"
                        id={`${item.value}`}
                        name={name}
                        value={item.value}
                        onChange={(e) => {
                            onChange(e);
                            setRadioButtonValue(e.target.value);
                        }}
                        checked={item.value === radioButtonValue}
                    />
                    <label htmlFor={`${item.value}`}>{item.title}</label>
                </div>
            ))}
        </div>
    );
};
