import React, {FC} from "react";
import {FormikValues} from "formik";
import * as Yup from "yup";
import * as _ from "lodash";

import {MSFormSelect} from "@medispend/common/src/components/MSFormSelect";
import {ButtonColors} from "@medispend/common/src/components/MSButton/types";
import {getRequiredMessage} from "@medispend/common/src/constants";
import {FormField} from "@medispend/common/src/formBuilder/types";
import {REQUIRED_TYPE} from "../constants";
import {StandardDropdownTypeField, YupObject} from "../../common/types";
import {useRegisterFields} from "../hooks/useRegisterFields";


interface DropdownTypeFieldsProps {
    formik: FormikValues,
    handleChangeFieldType: (validationRules: YupObject) => void,
    editableField: FormField
}

export const StandardDropdownBasedTypeFields: FC<DropdownTypeFieldsProps> = ({formik, handleChangeFieldType, editableField}) => {
    const {errors, touched} = formik;

    const initialValues: StandardDropdownTypeField = {
        requiredType: "OPTIONAL"
    };

    const editableFieldValues = _.pick(editableField, ["dropdown", "defaultValue", "requiredType"]);
    const validationSchema = {
        requiredType: Yup.mixed()
            .required(getRequiredMessage("Required Type"))
    };
    const fieldsList = ["requiredType"];
    useRegisterFields(handleChangeFieldType, validationSchema, initialValues, (editableFieldValues as StandardDropdownTypeField), fieldsList, formik);

    return (<>
        <MSFormSelect
            name="requiredType"
            isDisabled={false}
            activeVariant={null}
            options={REQUIRED_TYPE}
            fieldLabel="Required Field"
            placeholder="Select"
            value={formik.values.requiredType}
            isRequired={false}
            error={(touched.requiredType && errors.requiredType) || ""}
            placeVariant="grid"
            size="md"
            handleChange={(value) => formik.setFieldValue("requiredType", value)}
            variant={ButtonColors.white}
        />
    </>);
};
