import React, {useMemo} from "react";
import {FieldHandleAction} from "../../formBuilder/types";
import {BooleanToggleOption, FilterValue} from "../../types";
import {MSToggle, MSToggleProps} from "../MSToggle";

export interface MSBooleanToggleProps extends Omit<MSToggleProps, "options" | "value"> {
    value?: boolean;
    options: BooleanToggleOption[]
}

export const MSBooleanToggle: React.FC<MSBooleanToggleProps> = ({handleChange, value, options, ...props}) => {
    const stringValue = useMemo(() => String(value), [value]);
    const stringOptions = useMemo(
        () => options.map((option => ({uiLabel: option.uiLabel, value: String(option.value)}))),
        [options]
    );
    const onChange = (optionData: FilterValue, name: string, isHandleAction: boolean, handleAction: FieldHandleAction) => {
        const value = optionData === "true";
        handleChange(value, name, isHandleAction, handleAction);
    };
    return <MSToggle {...props} handleChange={onChange} value={stringValue} options={stringOptions} />;
};
