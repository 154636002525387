import React, {FC, useCallback, useMemo, useState, useEffect} from "react";
import {mergeMap, Observable, of, zip} from "rxjs";
import {useHistory} from "react-router-dom";

import {FilterOption, ResponseState, FilterParams} from "@medispend/common/src/types";
import {MSTable} from "@medispend/common/src/components/MSTable";
import {ScreenSort} from "@medispend/admin-common/src/types";
import {DEFAULT_PAGE_SIZE_OPTS, DEFAULT_PAGINATION} from "@medispend/common/src/constants";
import {
    CFF_FILTERS,
    DEFAULT_CFF_FILTERS,
    getCffColumns,
    DEFAULT_REQUEST_BODY,
    DEFAULT_SORT,
    SEARCH_TYPE
} from "./constants";
import {createUpdateAjax, getAjax} from "../services/ajax";
import {RequestBodyFilters, CffScreenFilters, PaginationParameters, CFFScreenTableData} from "../common/types";
import {useGetVersionData} from "../hooks/useGetVersionData";


export const CffScreenList: FC = () => {
    const versionInfo = useGetVersionData();
    const history = useHistory();
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
    const [filters, setFilters] = useState([...DEFAULT_CFF_FILTERS]);
    const [pageData, setPageData] = useState([]);
    const [availableFilters, setAvailableFilters] = useState(CFF_FILTERS);
    const [sort, setSort] = useState(DEFAULT_SORT);
    const [initialSort, setInitialSort] = useState("initial_sort=true&");
    const [isTableLoading, setIsLoading] = useState(false);
    const [pageSizeOpts, setPageSizeOpts] = useState(DEFAULT_PAGE_SIZE_OPTS);
    const [requestBody, setRequestBody] = useState({...DEFAULT_REQUEST_BODY});
    const [search, setSearch] = useState("");

    const updatePagination = useCallback((cffData: CFFScreenTableData) => {
        setPagination({
            ...pagination,
            total: cffData.totalElements,
            page: cffData.number,
            pageSize: cffData.size
        });
        setPageData(cffData.content);
    }, [pagination]);

    const getRecords = useCallback((apiUrl: string, requestBody: RequestBodyFilters<CffScreenFilters>) => {
        setIsLoading(true);
        getEntityData(apiUrl, requestBody).subscribe((response: ResponseState) => {
            updatePagination(response.data);
            setIsLoading(false);
        });
    }, [updatePagination]);

    useEffect(() => {
        setIsLoading(true);
        zip(getEntityData(`/api/screen/find?${initialSort}page=0&size=10&sort=client,asc`, requestBody),
            getAjax("/api/filters")).subscribe(([cffFieldsList, filters]) => {
            const updatedFilters = availableFilters.map((filter) => ({...filter, options: filters.data[filter.name].map((item: FilterParams) => {
                return {...item, value: item.id};
            })}));
            setAvailableFilters(updatedFilters);
            updatePagination(cffFieldsList.data);
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getApiUri = useCallback((property: PaginationParameters = {}, sortProps = sort, sortState = initialSort) => {
        const apiUrlParams: PaginationParameters = {
            page: 0,
            size: pagination.pageSize,
            ...property
        };

        const sortParams = Object.keys(sortProps).reduce((acc: string, item: string) => {
            sortProps[item] && (acc = acc.concat(`&sort=${item},${sortProps[item]}`));
            return acc;
        }, "");
        return `/api/screen/find?${sortState}page=${apiUrlParams.page}&size=${apiUrlParams.size}${sortParams}`;
    }, [initialSort, pagination.pageSize, sort]);

    const onFilterClear = useCallback(() => {
        const apiUrl = getApiUri({}, DEFAULT_SORT);
        setFilters([...DEFAULT_CFF_FILTERS]);
        setSort({...DEFAULT_SORT});
        setSearch("");
        const updatedRequestBody = {...DEFAULT_REQUEST_BODY, searchQuery: ""};
        setRequestBody(updatedRequestBody);
        getRecords(apiUrl, updatedRequestBody);
    }, [getApiUri, getRecords, requestBody.searchQuery]);

    const getEntityData = (apiUrl: string, requestBody: RequestBodyFilters<CffScreenFilters>): Observable<ResponseState> => {
        return createUpdateAjax("post", apiUrl, requestBody).pipe(
            mergeMap((response: ResponseState) => {
                return of(response);
            }));
    };

    const onToggleSort = useCallback((sortValue: ScreenSort) => {
        const apiUrl = getApiUri({}, sortValue, "");
        setInitialSort("");
        setSort(sortValue);
        getRecords(apiUrl, requestBody);
    }, [getRecords, requestBody, getApiUri]);

    const onFilterChange = useCallback((filterValue: FilterOption) => {
        const apiUrl = getApiUri();
        const newFilter = filterValue.name === "searchQuery" ? {} : {[filterValue.name]: filterValue.value};
        const updatedRequestBody: RequestBodyFilters<CffScreenFilters> = {
            filters: {
                ...requestBody.filters,
                ...newFilter
            },
            searchQuery: filterValue.name === "searchQuery" ? (filterValue.value as string) : requestBody.searchQuery
        };
        const filterIndex = filters.findIndex((filter) => filter.name === filterValue.name);
        filters[filterIndex] = filterValue;
        setFilters([...filters]);
        setSearch(updatedRequestBody?.searchQuery || "");
        setRequestBody(updatedRequestBody);
        getRecords(apiUrl, updatedRequestBody);

    }, [getRecords, requestBody, getApiUri, filters]);

    const tablePagination = useMemo(() => {
        const onPageChange = (page: number) => {
            const apiUrl = getApiUri({page});
            getRecords(apiUrl, requestBody);
        };
        return {...pagination, onPageChange};
    }, [getRecords, pagination, requestBody, getApiUri]);

    const tablePageSize = useMemo(() => {
        const onPageSizeChange = (size: number) => {
            const apiUrl = getApiUri({size});
            setPageSizeOpts({...pageSizeOpts, pageSize: size});
            getRecords(apiUrl, requestBody);
        };

        return {...pageSizeOpts, onPageSizeChange};
    }, [getRecords, pageSizeOpts, requestBody, getApiUri]);

    const redirectToScreen = (screenId: string, clientId: string) => {
        const clientIdInfo = clientId ? `?clientId=${clientId}` : "";
        history.push(`/screen/${screenId}/field${clientIdInfo}`);
    };

    return (<div>
        <div className="header-wrapper">
            <h1 className="header">Custom Form Fields</h1>
        </div>
        <div className="table-wrapper">
            <MSTable
                columns={getCffColumns(sort, onToggleSort, redirectToScreen)}
                data={pageData}
                showFooter
                showHeader
                showSearchFilter
                showPagination
                showFilters
                showSearchName
                searchType={SEARCH_TYPE}
                onFilterChange={onFilterChange}
                filtersValues={filters}
                pageSizeOpts={tablePageSize}
                availableFilters={availableFilters}
                showSorting={false}
                pagination={tablePagination}
                onFilterClear={onFilterClear}
                loading={isTableLoading}
                clearSearchWithFilters
                searchValue={search}
            />
        </div>
    </div>);
};
