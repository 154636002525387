import React, {FC} from "react";
import {FormikValues} from "formik";
import * as Yup from "yup";
import * as _ from "lodash";

import {MSFormSelect} from "@medispend/common/src/components/MSFormSelect";
import {MSFormMultiSelect} from "@medispend/common/src/components/MSFormMultiSelect";
import {ButtonColors} from "@medispend/common/src/components/MSButton/types";
import {getRequiredMessage} from "@medispend/common/src/constants";
import {FormField} from "@medispend/common/src/formBuilder/types";
import {REQUIRED_TYPE} from "../constants";
import {MultiSelectTypeField, YupObject} from "../../common/types";
import {useRegisterFields} from "../hooks/useRegisterFields";
import {useGetDropdowns} from "../hooks/useGetDropdowns";
import ListWrapper from "./typeWithItems/ListWrapper";
import {getListOptions} from "./typeWithItems/helpers";


interface MultiSelectFieldsProps {
    formik: FormikValues,
    handleChangeFieldType: (validationRules: YupObject) => void,
    editableField: FormField
}

export const MultiSelectTypeFields: FC<MultiSelectFieldsProps> = ({formik, handleChangeFieldType, editableField}) => {
    const {errors, touched} = formik;
    const refreshDropdowns = useGetDropdowns();
    const initialValues: MultiSelectTypeField = {
        dropdown: {values: [], name: ""},
        defaultValue: [],
        requiredType: "OPTIONAL",
        dropdownId: formik.values?.dropdown?.id || null
    };
    const editableFieldValues = _.pick(editableField, ["dropdown", "defaultValue", "requiredType", "dropdownId"]);
    const validationSchema = {
        requiredType: Yup.mixed()
            .required(getRequiredMessage("Required Type")),
        dropdown: Yup.object().shape({
            values: Yup.array().min(1, getRequiredMessage("Dropdown Values"))
        })
    };
    const fieldsList = ["dropdown", "defaultValue", "requiredType", "dropdownId"];
    useRegisterFields(handleChangeFieldType, validationSchema, initialValues, (editableFieldValues as MultiSelectTypeField), fieldsList, formik);

    return (<>
        <MSFormSelect
            name="requiredType"
            isDisabled={false}
            activeVariant={null}
            options={REQUIRED_TYPE}
            fieldLabel="Required Field"
            placeholder="Select"
            value={formik.values.requiredType}
            isRequired={false}
            error={(touched.requiredType && errors.requiredType) || ""}
            placeVariant="grid"
            size="md"
            handleChange={(value) => formik.setFieldValue("requiredType", value)}
            variant={ButtonColors.white}
        />
        <ListWrapper
            name="dropdown"
            fieldLabel="Dropdown Values"
            isRequired={false}
            isDisabled={false}
            error={(touched.dropdown?.values && errors.dropdown?.values) || ""}
            list={formik.values.dropdown || initialValues.dropdown}
            refreshEntities={refreshDropdowns}
            handleChange={(savedDropdown) => {
                formik.setFieldValue("dropdown", savedDropdown);
                formik.setFieldValue("dropdownId", savedDropdown.id);

                // remove the items from default values which are not active anymore
                const activeDropValueCodes = savedDropdown.values.filter(value => !value.inactive).map(value => value.code);
                const currDefCodes: string[] = formik.values.defaultValue;
                const newDefValues = currDefCodes.filter(defCode => activeDropValueCodes.includes(defCode));
                formik.setFieldValue("defaultValue", newDefValues);
            }}
            listName="dropdown"
            minListLength={1}
        />
        <MSFormMultiSelect
            name="defaultValue"
            disabled={!formik.values?.dropdown?.values?.length}
            activeVariant={null}
            options={getListOptions(formik.values?.dropdown?.values || [])}
            fieldLabel="Default Value"
            placeholder="Select default value"
            value={formik.values.defaultValue}
            isRequired={false}
            error={(touched.defaultValue && errors.defaultValue) || ""}
            placeVariant="grid"
            size="md"
            handleChange={(value) => formik.setFieldValue("defaultValue", value)}
            variant={ButtonColors.white}
        />
    </>);
};
