import React, {FC} from "react";

import {MSButton} from "@medispend/common/src/components/MSButton";
import {ButtonColors, ButtonSizes} from "@medispend/common/src/components/MSButton/types";
import {MSIcon} from "@medispend/common/src/components/MSIcon";
import {TextSearch} from "@medispend/common/src/components/MSTable/TextSearch";
import {FormField} from "@medispend/common/src/formBuilder/types";
import {sortFormFieldsAsc} from "@medispend/common/src/utils/sort";
import {AvailableField} from "./AvailableField";


export interface AvailableFieldsStateProps {
    handleCreateField: () => void,
    availableFields: FormField[],
    onChangeSectionInfo: (field: FormField) => void,
    maxYPosition: number
}

export const AvailableFieldsState: FC<AvailableFieldsStateProps> = ({handleCreateField, availableFields, onChangeSectionInfo, maxYPosition}) => {
    return <>
        <h2>Available Fields</h2>
        <MSButton
            className="create-field"
            size={ButtonSizes.md}
            variant={ButtonColors.green}
            onClick={handleCreateField}>
            <span>
                <MSIcon icon="plus-circle" /> Create new field
            </span>
        </MSButton>

        <TextSearch
            showSearchSwitcher={false}
            showSearchName={false}
            onFilterChange={() => {return false;}}
            textSearchPlaceholder="Search"
        />
        {!!availableFields?.length && <div className="available-fields-wrapper">{availableFields.sort(sortFormFieldsAsc)
            .map((field) => {
                return (<AvailableField key={field.fieldId} field={field} onAddField={onChangeSectionInfo} maxYPosition={maxYPosition} />);
            })}</div>}
    </>;
};
