import React, {FC, useCallback, useState, useEffect} from "react";
import {HTML5Backend} from "react-dnd-html5-backend";
import {useQuery} from "@medispend/common/src/components/MSLayout";
import {createUpdateAjax} from "../../../services/ajax";
import {ResponseState} from "@medispend/common/src/types";
import {useFormik} from "formik";
import {DndProvider} from "react-dnd";
import classNames from "classnames";
import * as Yup from "yup";
import * as _ from "lodash";

import {
    REGULAR_EXP,
    getAlphaNumericMessage,
    getRequiredMessage,
    getRequiredMessagePlural
} from "@medispend/common/src/constants";
import {MODAL_TYPES} from "@medispend/common/src/components/MSGlobalModal/constants";
import {useGlobalModalContext} from "@medispend/common/src/components/MSGlobalModal";
import {MSFormSelect} from "@medispend/common/src/components/MSFormSelect";
import {MSModal} from "@medispend/common/src/components/MSModal";
import {MSButton} from "@medispend/common/src/components/MSButton";
import {MSRadioButton} from "@medispend/common/src/components/MSRadioButton";
import MSInput from "@medispend/common/src/components/MSInput";
import {CheckboxColors, MSCheckbox} from "@medispend/common/src/components/MSCheckbox";
import {ButtonColors, ButtonSizes} from "@medispend/common/src/components/MSButton/types";
import {sortFormFiledByYPos} from "@medispend/common/src/utils/sort";
import notification, {MessagesType} from "@medispend/common/src/components/MSNotifications";
import {ListFieldType, EditorStates, ListTypes} from "../../../common/types";
import {getFieldIndexByCode} from "../../../helpers";
import {ItemView} from "./ItemView";
import {useExistingListsContext} from "../../../context/ExistingListsContext";


interface DropdownEditorProps {
    show: boolean;
    handleApprove: (values: ListFieldType) => void;
    onChange: (e: ListTypes) => void;
    list: ListFieldType;
    listType: ListTypes;
    handleCancel: () => void;
    listName: "dropdown" | "toggle";
    minListLength: number;
    maxListLength?: number;
}

export const ListEditor: FC<DropdownEditorProps> = ({show, handleApprove, onChange, list, listType, handleCancel, listName, minListLength, maxListLength}: DropdownEditorProps) => {
    const listInfo = useExistingListsContext();
    const lib = {
        dropdown: {
            existingList: listInfo.dropdowns,
            changeExistingList: listInfo.setExistingDropdowns,
            switcherItems: [{title: "Create New Dropdown", value: "CREATE_NEW"}, {
                title: "Use Existing Dropdown",
                value: "USE_EXISTING"
            }],
            existingListInfo: {
                fieldLabel: "Existing dropdowns",
                placeholder: "Select Dropdown",
                infoMessage: "Dropdown value changes will be applied to all fields using this\n" +
                    "                        dropdown list."
            },
            listNameLabel: "Dropdown Name",
            valuesLabel: "Dropdown Values"
        },
        toggle: {
            existingList: listInfo.toggles,
            changeExistingList: listInfo.setExistingToggles,
            switcherItems: [{title: "Create New Toggle", value: "CREATE_NEW"}, {
                title: "Use Existing Toggle",
                value: "USE_EXISTING"
            }],
            existingListInfo: {
                fieldLabel: "Existing toggles",
                placeholder: "Select Toggle",
                infoMessage: "Toggle value changes will be applied to all fields using this\n" +
                    "                        toggle list."
            },
            listNameLabel: "Toggle Name",
            valuesLabel: "Toggle Values"
        }
    };
    const {existingList, changeExistingList, switcherItems, existingListInfo, listNameLabel, valuesLabel} = lib[listName];
    const [editableListItem, setEditableListItem] = useState(null);
    const [currentListType, setCurrentListType] = useState(listType);
    const defaultEditorState: ListFieldType = {values: [], name: ""};
    const query = useQuery();
    const editorStates = {
        [ListTypes.USE_EXISTING]: defaultEditorState,
        [ListTypes.CREATE_NEW]: defaultEditorState
    };
    const defaultValue = {
        code: "",
        label: "",
        inactive: false,
        yPosition: 0
    };
    const [editorState, setEditorState] = useState<EditorStates>(editorStates);
    const [defaultModalData, setDefaultModalData] = useState<EditorStates>(editorStates);
    const [overlay, setOverlay] = useState(false);

    const isEqual = () => {
        const stateTypes = Object.values(ListTypes);
        const diff = stateTypes.find((state) => {
            const defaultData = defaultModalData[state];
            const editorData = editorState[state];
            return defaultData.name !== editorData.name ||
                _.differenceWith(defaultData.values, editorData.values, _.isEqual).length !== 0 ||
                (defaultData.values.length !== editorData.values.length) || !_.isEqual(defaultValue, formik.values);
        });
        return !diff;
    };
    useEffect(() => {
        defaultModalData[listType] = _.cloneDeep(list);
        setDefaultModalData(_.cloneDeep(defaultModalData));
    }, []);

    useEffect(() => {
        editorState[currentListType] = _.cloneDeep(list);
        setEditorState({...editorState});
        commonFormik.resetForm({values: _.cloneDeep(list)});
    }, [list]);

    useEffect(() => {
        commonFormik.resetForm({values: editorState[currentListType]});
        formik.resetForm({values: defaultValue});
    }, [currentListType]);

    const validationRules = {
        code: Yup.string()
            .matches(REGULAR_EXP.alphaNumericRegExp, getAlphaNumericMessage("Code"))
            .test("test-code-unique", "The code must be unique for the dropdown",
                function (value) {
                    // unique comparison on BE is case-insensitive. So we need to ignore case on checking the equality
                    const codeList = commonFormik.values.values.map((field) => field.code?.toLowerCase());
                    return !(codeList.includes(value?.toLowerCase()) && !editableListItem);
                })
            .required(getRequiredMessage("Code")),
        label: Yup.string()
            .required(getRequiredMessage("Label"))
    };

    const commonFormValidationRules = {
        name: Yup.string()
            .matches(REGULAR_EXP.alphaNumericRegExpWithSpace, getAlphaNumericMessage("Name"))
            .required(getRequiredMessage("Name")),
        values: Yup.array().compact(item => item.inactive).ensure().min(minListLength, minListLength > 1
            ? getRequiredMessagePlural(`At least ${minListLength} active items`)
            : getRequiredMessage(`At least ${minListLength} active item`))
    };
    const validationSchema = Yup.object().shape(validationRules);

    const formik = useFormik({
        initialValues: {...defaultValue},
        onSubmit: dropdownItem => {
            const dropdownItems = [...commonFormik.values.values];
            const updatedFieldIndex = dropdownItems.findIndex((field) => field.code === dropdownItem.code);
            if (updatedFieldIndex !== -1) {
                dropdownItems[updatedFieldIndex] = dropdownItem;
            } else {
                dropdownItems.push({...dropdownItem, yPosition: dropdownItems.length + 1});
            }
            commonFormik.resetForm({values: {...commonFormik.values, values: dropdownItems}});
            formik.resetForm({values: defaultValue});
            setEditableListItem(null);
            editorState[currentListType].values = dropdownItems;
        },
        validationSchema: validationSchema
    });

    const commonFormik = useFormik({
        initialValues: {name: list.name, values: list.values},
        onSubmit: values => {
            const clientId = query.get("clientId");
            const clientInfo = clientId ? `?clientId=${clientId}` : "";

            createUpdateAjax("post", `/api/${listName}${clientInfo}`, values, false)
                .subscribe((response: ResponseState) => {
                    if (response.error) {
                        notification(response.error?.response.message, MessagesType.ERROR);
                    } else if (response.data) {
                        const updatedListIndex = existingList.findIndex((dropdown) => dropdown.id === response.data.id);
                        const updatedList = [...existingList];
                        if (updatedListIndex !== -1) {
                            updatedList[updatedListIndex] = response.data;
                        } else {
                            updatedList.push(response.data);
                        }
                        changeExistingList(updatedList);
                        handleApprove(response.data);
                        onChange(currentListType);
                    }
                });

        },
        validationSchema: Yup.object().shape(commonFormValidationRules)
    });

    const {errors, touched} = formik;

    const moveField = useCallback((dragField, dropField) => {
        if (dragField.code === dropField.code) {
            return false;
        }
        const prevFields = commonFormik.values.values.filter((field) => !field.inactive);
        const dragFieldIndex = getFieldIndexByCode(prevFields, dragField);
        const dropFieldIndex = getFieldIndexByCode(prevFields, dropField);
        const updatedFields = prevFields.map((field) => {
            if (dragField.code === field.code) {
                return {...field, yPosition: prevFields[dropFieldIndex].yPosition};
            }
            if (dropField.code === field.code) {
                return {...field, yPosition: prevFields[dragFieldIndex].yPosition};
            }
            return {...field};
        });
        const values = [...updatedFields.sort(sortFormFiledByYPos), ...commonFormik.values.values.filter((field) => field.inactive)];
        editorState[ListTypes.USE_EXISTING].values = _.cloneDeep(values);
        setEditorState({...editorState});
        commonFormik.setFieldValue("values", values);
    }, [commonFormik, editorState]);

    const onEditField = useCallback((field) => {
        setEditableListItem(field);
        formik.resetForm({values: field});
    }, []);

    const {showModal: showGlobalModal, hideModal: hideGlobalModal} = useGlobalModalContext();
    const showNotification = () => {
        setOverlay(true);
        showGlobalModal(MODAL_TYPES.CONFIRM_MODAL, {
            title: "You have unsaved changes",
            text: "Are you sure you want to continue?",
            onConfirm: () => {
                hideGlobalModal();
                setOverlay(false);
                handleCancel();
            },
            onCancel: () => setOverlay(false)
        });
    };

    const handleCancelModal = () => {
        isEqual() ? handleCancel() : showNotification();
    };

    const handleCancelEditingItem = () => {
        formik.resetForm({values: defaultValue});
        setEditableListItem(null);
    };

    const hasAnyValueForNewItem = !!formik.values.code || !!formik.values.label;
    const body = <>
        <form onSubmit={commonFormik.handleSubmit}>
            <MSRadioButton
                name="create-list"
                value={currentListType}
                items={switcherItems}
                onChange={(e) => {
                    editorState[currentListType] = commonFormik.values;
                    handleCancelEditingItem();
                    setCurrentListType(e.target.value as ListTypes);
                }}
            />
            {currentListType === "USE_EXISTING" && (
                <div className="list-type-wrapper">
                    <MSFormSelect
                        name="fieldType"
                        activeVariant={null}
                        options={existingList?.map((dropdown) => ({
                            value: dropdown.id,
                            uiLabel: dropdown.name
                        })) || []}
                        value={editorState["USE_EXISTING"].id}
                        fieldLabel={existingListInfo.fieldLabel}
                        placeholder={existingListInfo.placeholder}
                        isRequired={false}
                        isDisabled={false}
                        error=""
                        placeVariant="form"
                        size="md"
                        handleChange={(value) => {
                            formik.setFieldValue("fieldType", value);
                            editorState["USE_EXISTING"] = existingList.find((dropdown) => dropdown.id === value);
                            setEditorState({...editorState});
                            commonFormik.resetForm({values: editorState["USE_EXISTING"]});
                        }}
                        variant={ButtonColors.white}
                    />
                    <div className="info-message">
                        {existingListInfo.infoMessage}
                    </div>
                </div>
            )}
            <MSInput
                name="name"
                fieldLabel={listNameLabel}
                isRequired={false}
                isDisabled={false}
                error={(commonFormik.touched.name && (Array.isArray(commonFormik.errors.name) ? commonFormik.errors.name[0] : commonFormik.errors.name)) || ""}
                value={commonFormik.values.name}
                handleChange={(value) => {
                    value = value.trim();
                    commonFormik.setFieldValue("name", value);
                    editorState[currentListType].name = value;
                }}
                variant="form"
                className="input-wrapper"
            />
        </form>
        <form onSubmit={formik.handleSubmit}>
            <div className="form-row">
                <div className="code-wrapper">
                    <MSInput
                        name="code"
                        fieldLabel="Code"
                        isRequired={false}
                        isDisabled={!!editableListItem}
                        error={(touched.code && (Array.isArray(errors.code) ? errors.code[0] : errors.code)) || ""}
                        value={formik.values.code}
                        handleChange={(value) => formik.setFieldValue("code", value.trim())}
                        variant="form"
                        className="code"
                    />
                    <MSInput
                        name="label"
                        fieldLabel="Label"
                        isRequired={false}
                        isDisabled={false}
                        error={(touched.label && errors.label) || ""}
                        value={formik.values.label}
                        handleChange={(value) => formik.setFieldValue("label", value.trim())}
                        variant="form"
                        className="label"
                    />
                </div>
                <div className="checkbox-wrapper">
                    <MSCheckbox
                        title="Inactive"
                        variant={CheckboxColors.green}
                        onClick={(value) => formik.setFieldValue("inactive", value)}
                        active={formik.values.inactive}
                    />
                    {editableListItem && <MSButton
                        className=""
                        size={ButtonSizes.sm}
                        variant={ButtonColors.grey100}
                        onClick={handleCancelEditingItem}>
                        <div className="ms-button-title">
                            Cancel
                        </div>
                    </MSButton>
                    }
                    <MSButton
                        className=""
                        size={ButtonSizes.sm}
                        variant={ButtonColors.green}
                        onClick={formik.handleSubmit}
                        disabled={(commonFormik.values.values.filter(value => !value.inactive).length >= maxListLength && (!editableListItem || editableListItem.inactive)
                            || !hasAnyValueForNewItem) }
                    >
                        <div className="ms-button-title">
                            {editableListItem ? "Apply" : "Add"}
                        </div>
                    </MSButton>
                </div>
            </div>
            {hasAnyValueForNewItem && !editableListItem && commonFormik.values.values.filter(value => !value.inactive).length >= maxListLength && (<div className="info-message">You can add maximum {maxListLength} values</div>)}
        </form>
        <div className="ms-form-group ms-form-input">
            <label className="form-label">{valuesLabel}</label>
            <div
                className={classNames("section-fields-container", {"has-error": commonFormik.touched.values && commonFormik.errors.values?.length})}>
                <div className="list-options">
                    <DndProvider backend={HTML5Backend}>
                        {commonFormik.values.values.filter((field) => !field.inactive).sort(sortFormFiledByYPos).map((field, index: number) => {
                            return <ItemView field={{...field, fieldId: field.id}} key={field.code} id={field.code}
                                index={index} moveField={moveField} onEditField={onEditField}
                                editable={editableListItem?.code === field.code} />;
                        })}
                    </DndProvider>
                </div>
                {!!commonFormik.values.values.filter((field) => field.inactive).length && (
                    <div className="inactive-fields">
                        {commonFormik.values.values.filter((field) => field.inactive).map((field) => {
                            return (<div
                                className={classNames("section-field", {"is-editable": editableListItem?.code === field.code})}
                                key={field.code} onClick={() => onEditField(field)}>
                                {`${field.code} - ${field.label}`}
                            </div>);
                        })}
                    </div>
                )}
                {commonFormik.touched.values && !!commonFormik.errors.values?.length &&
                <div className="error-message">{commonFormik.errors.values}</div>}
            </div>
        </div>
    </>;
    return (<MSModal
        overlay={overlay}
        show={show}
        body={body}
        footer={<>
            <MSButton key="cancel" onClick={handleCancelModal} variant="grey-100" size="lg">Cancel</MSButton>
            <MSButton
                key="approve"
                onClick={commonFormik.handleSubmit}
                variant="green"
                size="lg"
                disabled={!!editableListItem}
            >
                Save
            </MSButton>
        </>}
    />);
};
