import React, { FC, ReactElement, useState } from "react";
import Dropzone from "react-dropzone";

import { FieldHandleAction, FormField, Mode } from "../../formBuilder/types";

import "./index.scss";
import { MSLabel } from "../MSLabel";

interface MSFileUploadProps {
    name?: string;
    value?: string;
    mode?: Mode;
    error?: string;
    fieldLabel: string;
    isRequired: boolean;
    customAreaLabel?: ReactElement;
    handleChange?: (
        value: File,
        name: string,
        isHandleAction: boolean,
        handleAction: FieldHandleAction,
        updateField?: keyof FormField
    ) => void;
    isHandleAction?: boolean;
    handleAction?: FieldHandleAction;
}

const MSFileUploader: FC<MSFileUploadProps> = (props: MSFileUploadProps) => {
    const {
        name,
        error,
        value,
        fieldLabel,
        mode,
        isRequired,
        customAreaLabel,
        handleChange,
        isHandleAction,
        handleAction,
    } = props;

    const [fileName, setFileName] = useState(value);

    const handleFileChange = (files: File[]) => {
        setFileName(files[0].name);
        handleChange && handleChange(files[0], name, isHandleAction, handleAction);
    };

    const areaLabel = customAreaLabel || (
        <p>
            Drag and drop file here or{" "}
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    return false;
                }}
            >
                choose file
            </a>
        </p>
    );

    return (
        <div className="ms-form-group">
            {fieldLabel && <MSLabel isRequired={isRequired} fieldLabel={fieldLabel} isPencilDisplayed={false} />}
            <div className="ms-file-uploader-container">
                {fileName && <div className="ms-file-uploader-fileName">{fileName}</div>}
                {mode !== Mode.READ && (
                    <Dropzone onDrop={handleFileChange}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: "ms-file-uploader" })}>
                                <input {...getInputProps()} />
                                {areaLabel}
                            </div>
                        )}
                    </Dropzone>
                )}
            </div>
            {!!error?.length && <div className="error-message">{error}</div>}
        </div>
    );
};

export default MSFileUploader;
