import React, {FC} from "react";
import {FormikValues} from "formik";
import * as Yup from "yup";
import * as _ from "lodash";

import {MSFormSelect} from "@medispend/common/src/components/MSFormSelect";
import {ButtonColors} from "@medispend/common/src/components/MSButton/types";
import {getRequiredMessage} from "@medispend/common/src/constants";
import {FormField} from "@medispend/common/src/formBuilder/types";
import {REQUIRED_TYPE} from "../constants";
import {DropdownTypeField, YupObject} from "../../common/types";
import {useRegisterFields} from "../hooks/useRegisterFields";
import {useGetDropdowns} from "../hooks/useGetDropdowns";
import ListWrapper from "./typeWithItems/ListWrapper";
import {getListOptions} from "./typeWithItems/helpers";


interface DropdownTypeFieldsProps {
    formik: FormikValues,
    handleChangeFieldType: (validationRules: YupObject) => void,
    editableField: FormField
}

export const DropdownTypeFields: FC<DropdownTypeFieldsProps> = ({formik, handleChangeFieldType, editableField}) => {
    const {errors, touched} = formik;

    const initialValues: DropdownTypeField = {
        dropdown: {values: [], name: "", id: null},
        defaultValue: "",
        requiredType: "OPTIONAL",
        dropdownId: formik.values?.dropdown?.id || null
    };

    const refreshDropdowns = useGetDropdowns();

    const editableFieldValues = _.pick(editableField, ["dropdown", "defaultValue", "requiredType", "dropdownId"]);
    const validationSchema = {
        defaultValue: Yup.string().nullable(),
        requiredType: Yup.mixed()
            .required(getRequiredMessage("Required Type")),
        dropdown: Yup.object().shape({
            values: Yup.array().min(1, getRequiredMessage("Dropdown Values"))
        })
    };
    const fieldsList = ["dropdown", "defaultValue", "requiredType", "dropdownId"];
    useRegisterFields(handleChangeFieldType, validationSchema, initialValues, (editableFieldValues as DropdownTypeField), fieldsList, formik);

    return (<>
        <MSFormSelect
            name="requiredType"
            isDisabled={false}
            activeVariant={null}
            options={REQUIRED_TYPE}
            fieldLabel="Required Field"
            placeholder="Select"
            value={formik.values.requiredType}
            isRequired={false}
            error={(touched.requiredType && errors.requiredType) || ""}
            placeVariant="grid"
            size="md"
            handleChange={(value) => formik.setFieldValue("requiredType", value)}
            variant={ButtonColors.white}
        />
        <ListWrapper
            name="dropdown"
            fieldLabel="Dropdown Values"
            isRequired={false}
            isDisabled={false}
            error={(touched.dropdown?.values && errors.dropdown?.values) || ""}
            list={formik.values.dropdown || initialValues.dropdown}
            handleChange={(savedDropdown) => {
                formik.setFieldValue("dropdown", savedDropdown);
                formik.setFieldValue("dropdownId", savedDropdown.id);

                // reset defaultValue if this item is not active anymore
                const activeDropValues = savedDropdown.values.filter(value => !value.inactive);
                const currDefValue = formik.values.defaultValue;
                if (currDefValue && activeDropValues.findIndex(value => value.code === currDefValue) === -1) {
                    formik.setFieldValue("defaultValue", null);
                }
            }}
            refreshEntities={refreshDropdowns}
            listName="dropdown"
            minListLength={1}
        />
        <MSFormSelect
            name="defaultValue"
            isDisabled={!formik.values?.dropdown?.values?.length}
            activeVariant={null}
            options={getListOptions(formik.values?.dropdown?.values || [])}
            fieldLabel="Default Value"
            placeholder="Select default value"
            value={formik.values.defaultValue}
            isRequired={false}
            error={(touched.defaultValue && errors.defaultValue) || ""}
            placeVariant="grid"
            size="md"
            handleChange={(value) => formik.setFieldValue("defaultValue", value)}
            variant={ButtonColors.white}
        />
    </>);
};
