import {createContext, useContext} from "react";
import {ListFieldType} from "../common/types";

interface ListsContext {
    dropdowns: ListFieldType[],
    setExistingDropdowns: (dropdowns: ListFieldType[]) => void,
    toggles: ListFieldType[],
    setExistingToggles: (toggles: ListFieldType[]) => void,
}

const initState: ListsContext = {
    dropdowns: [],
    setExistingDropdowns: () => {/**/},
    toggles: [],
    setExistingToggles: () => {/**/}
};

export const ExistingListsContext = createContext(initState);
export const useExistingListsContext = () => useContext(ExistingListsContext);
