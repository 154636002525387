import React, {FC, useEffect, useState} from "react";
import CurrencyInput from "react-currency-input-field";
import classNames from "classnames";

import {MSFormSelect} from "@medispend/common/src/components/MSFormSelect";
import {ButtonColors} from "../MSButton/types";
import {FieldHandleAction, FormField, Mode} from "../../formBuilder/types";
import {MSLabel} from "../MSLabel";

import "./index.scss";
import {adjustDecimals, padWithZeros} from "../../utils/string/pad";
import _ from "lodash";

export interface MSCurrencyProps {
    value: string;
    fieldLabel: string;
    isRequired: boolean;
    isDisabled: boolean;
    error?: string;
    inputType?: "string";
    name?: string;
    handleChange?: (value: string, name: string, isHandleAction: boolean, handleAction: FieldHandleAction, updateField?: keyof FormField) => void;
    isHandleAction?: boolean;
    handleAction?: FieldHandleAction;
    variant?: "form" | "grid";
    className?: string;
    mode?: Mode;
    dropdown: {
        code: string,
        label: string,
        decimalPlaces: number
    }[];
    currency: string;
    defaultCurrency: string;
    isPencilDisplayed: boolean;
}

export const MSCurrency: FC<MSCurrencyProps> = ({dropdown, currency, defaultCurrency, value, name, fieldLabel, isRequired, isDisabled, error, inputType = "string", handleChange, isHandleAction, handleAction, variant = "form", className, mode, isPencilDisplayed}) => {
    const [inputValue, setInputValue] = useState(null);
    const decLimit = dropdown.find(item => item.code === (currency || defaultCurrency)).decimalPlaces;

    useEffect(() => {
        if (inputValue && mode !== Mode.READ) {
            const adjustedValue = adjustDecimals(inputValue.toString(), decLimit);
            setInputValue(adjustedValue);
            handleChange && handleChange(adjustedValue?.replace(/,/g, "") || "", name, isHandleAction, handleAction);
        }
    }, [currency]);

    useEffect(() => {
        setInputValue((value === undefined || value === null) ? "" : value);
    }, [value]);

    const ccyOptions = {
        style: "currency",
        currency: currency,
        currencyDisplay: "code",
        minimumFractionDigits: decLimit
    };
    const ccyViewValue = currency && _.isNumber(value) ? (value as number).toLocaleString("en-US", ccyOptions) : (value || "-");
    const inputContent = <>
        <MSLabel isRequired={isRequired} fieldLabel={fieldLabel} isPencilDisplayed={isPencilDisplayed} error={error} />
        <div className={classNames({"has-error": error?.length})}>
            {mode === "READ" ? <div className="value">{ccyViewValue}</div> :
                <div className={classNames("ms-currency-control", {"has-error": error?.length})}>
                    <CurrencyInput
                        className="ms-input"
                        name={name}
                        placeholder={padWithZeros("0", decLimit, true)}
                        defaultValue={inputValue}
                        decimalsLimit={decLimit}
                        value={inputValue}
                        onValueChange={(value, name) => {
                            setInputValue(value);
                            handleChange && handleChange(value?.replace(/,/g, "") || "", name, isHandleAction, handleAction);}
                        }
                        disabled={isDisabled}
                        type={inputType}
                        allowNegativeValue={false}
                        decimalScale={decLimit}
                    />
                    <div className="ms-currency-wrapper">
                        <MSFormSelect
                            options={dropdown.map(item => ({...item, value: item.code, uiLabel: item.label}))}
                            title="MSFormSelect"
                            activeVariant={null}
                            placeVariant="grid"
                            fieldLabel=""
                            isRequired={false}
                            error=""
                            variant={ButtonColors.white}
                            value={currency || defaultCurrency}
                            handleChange={(optionData: string, name: string, isHandleAction: boolean, handleAction: FieldHandleAction) => {
                                handleChange(optionData, name, isHandleAction, handleAction, "currency");
                            }}
                            name={name}
                            isDisabled={isDisabled}
                            isHandleAction={isHandleAction}
                            handleAction={handleAction}
                        />
                    </div>
                </div>
            }
            {!!error?.length && <div className="error-message">{error}</div>}
        </div>
    </>;

    return (
        <>
            {variant === "form" && (
                <div className={`ms-form-group ms-form-input ${className}`}>
                    {inputContent}
                </div>
            )}
            {variant === "grid" && (
                <>
                    {inputContent}
                </>
            )}
        </>);
};
