import React, {FunctionComponent} from "react";

import {ButtonSizes, ButtonColors, ButtonTypes} from "../MSButton/types";
import {SelectOption} from "../MSSelect/BaseSelect/types";
import {FilterValue} from "../../types";
import {FieldHandleAction, FormField, Mode} from "../../formBuilder/types";

import "./index.scss";
import {ComponentByType, getPropsByComponent} from "../../formBuilder/constantsLib";
import {MSFormMultiSelect} from "../MSFormMultiSelect";


export interface MSSelectProps {
    options?: SelectOption[];
    title?: string;
    disabled?: boolean;
    placeholder?: string;
    size?: keyof typeof ButtonSizes;
    buttonType?: keyof typeof ButtonTypes;
    variant?: typeof ButtonColors[keyof typeof ButtonColors];
    activeVariant?: typeof ButtonColors[keyof typeof ButtonColors] | null;
    value?: any;
    handleChange?: (optionData: FilterValue, name: string, isHandleAction: boolean, handleAction: FieldHandleAction) => void;
    isHandleAction?: boolean;
    handleAction?: FieldHandleAction;
    fieldLabel: string;
    isRequired: boolean;
    error: string;
    placeVariant?: "form" | "grid";
    mode?: Mode;
    shouldShowClear?: boolean;
    dynamicFields?: FormField[]
}


export const MSFormMultiSelectWithChildren: FunctionComponent<MSSelectProps> = (props) => {
    const {
        handleChange,
        mode,
        placeVariant = "form",
        dynamicFields
    } = props;

    const dynamicContentBase =
        dynamicFields.map((dynamicField: FormField) => {
            const type: keyof typeof ComponentByType = dynamicField.fieldType;
            const FormComponent: any = ComponentByType[type];
            const props = getPropsByComponent(dynamicField, null)[type];
            return !dynamicField.isHidden && <div key={dynamicField.fieldId} className="ms-form-element">
                <FormComponent key={dynamicField.fieldId} {...props} isRequired={dynamicField.isAsteriskDisplayed}
                    mode={mode} handleChange={handleChange} />
            </div>;
        });
    const dynamicContent = dynamicContentBase != null ? <div className="dynamic-section col-num-2">{dynamicContentBase}</div> : <div />;

    return (
        <>
            <MSFormMultiSelect {...props} />
            {placeVariant === "form" && (
                <div className="ms-form-group">
                    {dynamicContent}
                </div>
            )}
            {placeVariant === "grid" && (
                {dynamicContent}
            )}
        </>
    );
};
