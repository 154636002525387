import React, {FC} from "react";
import {FormikValues} from "formik";
import {HyperlinkTypeField, YupObject} from "../../common/types";
import {FormField} from "@medispend/common/src/formBuilder/types";
import MSInput from "@medispend/common/src/components/MSInput";
import {MSFormSelect} from "@medispend/common/src/components/MSFormSelect";
import {REQUIRED_TYPE} from "../constants";
import {ButtonColors} from "@medispend/common/src/components/MSButton/types";
import * as _ from "lodash";
import * as Yup from "yup";
import {
    getRequiredMessage, getUrlMessage,
    REGULAR_EXP
} from "@medispend/common/src/constants";
import {useRegisterFields} from "../hooks/useRegisterFields";


interface HyperlinkTypeFieldsProps {
    formik: FormikValues,
    handleChangeFieldType: (validationRules: YupObject) => void,
    editableField: FormField
}

export const HyperlinkTypeFields: FC<HyperlinkTypeFieldsProps> = ({formik, handleChangeFieldType, editableField}) => {
    const {errors, touched, values} = formik;
    const initialValues: HyperlinkTypeField = {
        defaultValue: "",
        requiredType: "OPTIONAL"
    };
    const editableFieldValues = _.pick(editableField, ["defaultValue", "requiredType"]);

    const validationSchema = {
        defaultValue: Yup.string()
            .matches(REGULAR_EXP.urlRegExp, getUrlMessage("Default URL")),
        requiredType: Yup.mixed()
            .required(getRequiredMessage("Required Type"))
    };
    const fieldsList = ["maxFieldLength", "defaultValue", "requiredType", "decimalPlaces"];

    useRegisterFields(handleChangeFieldType, validationSchema, initialValues, (editableFieldValues as HyperlinkTypeField), fieldsList, formik);

    return (<>
        <MSInput
            name="defaultValue"
            fieldLabel="Default URL"
            isRequired={false}
            isDisabled={false}
            error={(touched.defaultValue && errors.defaultValue) || ""}
            value={values.defaultValue}
            handleChange={(value) => formik.setFieldValue("defaultValue", value)}
            isHandleAction
            variant="grid"
        />
        <MSFormSelect
            name="requiredType"
            isDisabled={false}
            activeVariant={null}
            options={REQUIRED_TYPE}
            fieldLabel="Required Field"
            placeholder="Select"
            value={values.requiredType}
            isRequired={false}
            error={(touched.requiredType && errors.requiredType) || ""}
            placeVariant="grid"
            size="md"
            handleChange={(value) => formik.setFieldValue("requiredType", value)}
            variant={ButtonColors.white}
        />
    </>);
};
