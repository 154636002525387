import React, {useEffect} from "react";
import {FormikProps} from "formik";
import {FormField} from "../../formBuilder/types";
import {ComponentsTypes} from "../../formBuilder/constantsLib";
import "./index.scss";

interface MSHintProps {
    parentFieldId: string;
    formik: FormikProps<{[key: string]: any}>,
    value: {
        [key: string]: {name: string, description: string}
    },
    name: string;
}

export const MSHint: React.FC<MSHintProps> = (props) => {

    const [sectionName] = props.name.split(".");

    const parrentFieldValue = sectionName
        ? props.formik.values[sectionName][props.parentFieldId]
        : props.formik.values[props.parentFieldId]; // TODO remove section name from field name, it doesn't make sense

    const hint = props.value[parrentFieldValue];

    if (!parrentFieldValue || !hint) return null;

    return (
        <div className="ms-hint">
            <div className="ms-hint__name">{hint.name}</div>
            <div className="ms-hint__description">{hint.description}</div>
        </div>
    );
};

export function isHintField(field: FormField): boolean {
    return field.fieldType === ComponentsTypes.HINT;
}