import * as Yup from "yup";
import {AnySchema, ArraySchema, DateSchema, NumberSchema, StringSchema} from "yup";

import {getDate} from "@medispend/common/src/utils";
import MSTextArea from "../components/MSTextArea";
import MSInput from "../components/MSInput";
import {MSFormSelect} from "../components/MSFormSelect";
import {MSFormMultiSelect} from "../components/MSFormMultiSelect";
import {MSDatepicker} from "../components/MSDatepicker";
import {FieldHandleAction, FormField} from "./types";
import {FilterValue} from "../types";
import {
    getAlphabeticMessage,
    getDecimalPlacesLengthMessage,
    getIntegerPlacesLengthMessage,
    getNumericMessage, getRequiredMessage,
    getUrlMessage,
    REGULAR_EXP
} from "../constants";
import {testAlphabeticString} from "../constants/yupCustomMethods";
import {MSFormMultiSelectWithChildren} from "../components/MSFormMultiSelectWithChildren";
import {MSToggle} from "../components/MSToggle";
import {MSCurrency} from "../components/MSCurrency";
import MSFileUploader from "../components/MSFileUploader";
import {MixedSchema} from "yup/lib/mixed";
import {MSAlertMessage, MSAlertMessageStyle} from "../components/MSAlertMessage";
import {MSHint} from "../components/MSHint";
import {MSHyperlink} from "../components/MSHyperlink";

import * as validationHelpers from "../formBuilder/helpers/validationHelpers";
import {YupObject} from "@medispend/cff/src/common/types";
import {MSReadOnly} from "../components/MSReadOnly";

export enum InputTypes {
    TEXT = "text",
    CHECK_BOX = "checkbox",
    DROPDOWN = "dropdown",
    DATE_PICKER = "datePicker"
}

export enum InputMask {
    ALPHANUMERIC = "ALPHANUMERIC",
    NUMERIC = "NUMERIC",
    ALPHABETIC = "ALPHABETIC",

    REGEX = "REGEX"
}

export enum ComponentsTypes {
    TEXT = "TEXT",
    TEXT_AREA = "TEXT_AREA",
    DATE = "DATE",
    CUSTOM_DROPDOWN = "CUSTOM_DROPDOWN",
    STANDARD_DROPDOWN = "STANDARD_DROPDOWN",
    CUSTOM_MULTISELECT = "CUSTOM_MULTISELECT",
    STANDARD_MULTISELECT = "STANDARD_MULTISELECT",
    NUMBER = "NUMBER",
    STANDARD_MULTISELECT_WITH_CHILDREN = "STANDARD_MULTISELECT_WITH_CHILDREN",
    CUSTOM_TOGGLE = "CUSTOM_TOGGLE",
    STANDARD_TOGGLE = "STANDARD_TOGGLE",
    CURRENCY = "CURRENCY",
    FILE = "FILE",
    HINT = "HINT",
    HYPERLINK = "HYPERLINK",
    READ_ONLY = "READ_ONLY",
}

export const ComponentByType = {
    [ComponentsTypes.TEXT_AREA]: MSTextArea,
    [ComponentsTypes.TEXT]: MSInput,
    [ComponentsTypes.NUMBER]: MSInput,
    [ComponentsTypes.STANDARD_DROPDOWN]: MSFormSelect,
    [ComponentsTypes.CUSTOM_DROPDOWN]: MSFormSelect,
    [ComponentsTypes.STANDARD_MULTISELECT]: MSFormMultiSelect,
    [ComponentsTypes.CUSTOM_MULTISELECT]: MSFormMultiSelect,
    [ComponentsTypes.DATE]: MSDatepicker,
    [ComponentsTypes.STANDARD_MULTISELECT_WITH_CHILDREN] : MSFormMultiSelectWithChildren,
    [ComponentsTypes.CUSTOM_TOGGLE]: MSToggle,
    [ComponentsTypes.STANDARD_TOGGLE]: MSToggle,
    [ComponentsTypes.CURRENCY]: MSCurrency,
    [ComponentsTypes.FILE]: MSFileUploader,
    [ComponentsTypes.HINT]: MSHint,
    [ComponentsTypes.HYPERLINK]: MSHyperlink,
    [ComponentsTypes.READ_ONLY]: MSReadOnly
};


//--> todo: should be consolidating validation logic by input types with admin???

const INPUT_MASK = "inputMask";

export const YupByType = {
    [ComponentsTypes.TEXT_AREA]: (): StringSchema<string> => Yup.string().trim(),
    [ComponentsTypes.TEXT]: (field: FormField): StringSchema<string> | NumberSchema<number> => {
        return validationHelpers.getTextFieldSchemasByField(field);
    },
    [ComponentsTypes.NUMBER]: (field: FormField): StringSchema<string> =>
        Yup.string()
            .matches(REGULAR_EXP.numberWithDotComma, getNumericMessage(field.fieldLabel))
            .test("test-decimal-places", getDecimalPlacesLengthMessage(field.decimalPlaces),
                (value) => {
                    let decimalPlaces = "";
                    if (value?.includes(".") || value?.includes(",")) {
                        const afterComma = value.split(",")[1];
                        const afterDot = value.split(".")[1];
                        decimalPlaces = afterComma || afterDot;
                    }
                    return decimalPlaces ? decimalPlaces.length <= field.decimalPlaces : true;
                }).nullable()
            .test("test-string-length", `${field.fieldLabel} length should be no more than ${field.maxFieldLength}`,
                (value) => (value?.length && field.maxFieldLength) ? value?.length <= field.maxFieldLength : true),
    [ComponentsTypes.STANDARD_DROPDOWN]: (): StringSchema<string> => Yup.string(),
    [ComponentsTypes.CUSTOM_DROPDOWN]: (): StringSchema<string> => Yup.string(),
    [ComponentsTypes.CUSTOM_TOGGLE]: (): StringSchema<string> => Yup.string(),
    [ComponentsTypes.STANDARD_TOGGLE]: (): StringSchema<string> => Yup.string(),
    [ComponentsTypes.STANDARD_MULTISELECT]: (field: FormField): ArraySchema<AnySchema> => {
        const inactiveOptions = Array.isArray(field.options) ? field.options.filter(option => option.inactive) : [];
        return Yup.array().test("inactive-values",
        // we have to keep this validation for now as we have one type in cff.
        // it could be used with any standard multiselect even if options don't have inactive property
            ({value}) => {
                const inactiveLabels = inactiveOptions.filter(option => value.includes(option.value)).map(option => option.uiLabel);
                const union = inactiveLabels.length > 1 ? "are" : "is";
                return `${inactiveLabels.join(", ")} ${union} no longer a valid selection.`;
            },
            (value) => {
                if (!Array.isArray(value)) return true;
                return !inactiveOptions.some(option => value.includes(option.value));
            });
    },
    [ComponentsTypes.CUSTOM_MULTISELECT]: (): ArraySchema<AnySchema> => Yup.array(),
    [ComponentsTypes.DATE]: (field: FormField): DateSchema => Yup.date().min(field.startLimit || 0, (props) => {
        return `${field.fieldLabel} cannot be in the past`;
    }),
    [ComponentsTypes.STANDARD_MULTISELECT_WITH_CHILDREN]: (): ArraySchema<AnySchema> => Yup.array(),
    [ComponentsTypes.CURRENCY]: (field: FormField): StringSchema<string> => Yup.string()
        .test("test-decimal-places", getDecimalPlacesLengthMessage(field.decimalPlaces),
            (value, fullObject: any) => {
                let decimalPlaces = "";
                if (value?.includes(".")) {
                    decimalPlaces = value.split(".")[1];
                }
                return decimalPlaces ? decimalPlaces.length <= field.dropdown.find((item: any) => item.code === fullObject.options?.parent[`${field.fieldId}-currency`]).decimalPlaces : true;
            }).nullable()
        .test("test-integer-places", getIntegerPlacesLengthMessage(field.maxNumberLength),
            (value) => {
                const integerPlaces = value?.split(".")[0];
                return (integerPlaces?.length && field.maxNumberLength) ? integerPlaces?.length <= field.maxNumberLength : true;
            }),
    [ComponentsTypes.FILE]: (): MixedSchema => Yup.mixed(),
    [ComponentsTypes.HINT]: (): MixedSchema => Yup.mixed(),
    [ComponentsTypes.HYPERLINK]: (field: FormField): StringSchema => Yup.string().matches(REGULAR_EXP.urlRegExp, getUrlMessage(field.fieldLabel)),
    [ComponentsTypes.READ_ONLY]: (): MixedSchema => Yup.mixed()
};

export const getFieldValue = (field: FormField) => field.value !== null ? field.value : field.defaultValue || null;

export const getPropsByComponent = (
    field: FormField,
    handleAction: (value: FilterValue, name: string, isHandleAction?: boolean, handleAction?: FieldHandleAction) => void,
    dynamicFields?: FormField[],
    formik?: any // TODO add better types for Formik
) => {
    const standardProps = {...field, value: getFieldValue(field)};
    return {
        [ComponentsTypes.TEXT_AREA]: standardProps,
        [ComponentsTypes.CUSTOM_TOGGLE]: standardProps,
        [ComponentsTypes.STANDARD_TOGGLE]: standardProps,
        [ComponentsTypes.TEXT]: {...standardProps, inputType: "string" as ("string" | "number")},
        [ComponentsTypes.NUMBER]: {...standardProps, inputType: "number" as ("string" | "number")},
        [ComponentsTypes.STANDARD_DROPDOWN]: {...standardProps, shouldShowClear: true, showTypeToSearch: true},
        [ComponentsTypes.CUSTOM_DROPDOWN]: {...standardProps, shouldShowClear: true, showTypeToSearch: true},
        [ComponentsTypes.STANDARD_MULTISELECT]: {...standardProps, shouldShowClear: true, showTypeToSearch: true},
        [ComponentsTypes.CUSTOM_MULTISELECT]: {...standardProps, shouldShowClear: true, showTypeToSearch: true},
        [ComponentsTypes.DATE]: {...field, label: field.fieldLabel, isOpenUp: true, isDropdown: true, placeholder: "Select Date", options:
                {value: getFieldValue(field),
                    uiLabel: "",
                    name: field.name}, onChange: (timestamp: number) => handleAction(getDate(timestamp), field.name)},
        [ComponentsTypes.STANDARD_MULTISELECT_WITH_CHILDREN]: {...standardProps, shouldShowClear: true, dynamicFields: dynamicFields, showTypeToSearch: true},
        [ComponentsTypes.CURRENCY]: standardProps,
        [ComponentsTypes.FILE]: standardProps,
        [ComponentsTypes.HINT]: {...standardProps, formik},
        [ComponentsTypes.HYPERLINK]: standardProps,
        [ComponentsTypes.READ_ONLY]: standardProps
    };
};

export const actionsLib = {
    HIDE_ELEMENT: (action: {doAction: boolean}): {isHidden: boolean} => {
        return {
            isHidden: action.doAction
        };
    },
    LOCK_RECORD: (action: {doAction: boolean}): {isDisabled: boolean} => {
        return {
            isDisabled: action.doAction
        };
    },
    SHOW_MESSAGE: (action: {message: string, showMessage: boolean}): MSAlertMessage => {
        // right now we show only regular (red) messages came from BR
        return action.showMessage
            ? {style: MSAlertMessageStyle.REGULAR, message: action.message}
            : null;
    }
};
