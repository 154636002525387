import {FormField, SectionInfo, DropdownItem, FormEmptyField, TableCell} from "../formBuilder/types";
import {SelectOption} from "../components/MSSelect/BaseSelect/types";


export const sortAsc = (a: string | number, b: string | number): number => {
    if (a === null || a === "") {
        return -1;
    }

    if (b === null || b === "") {
        return 1;
    }

    if (a < b) {
        return -1;
    }
    return ((a > b) ? 1 : 0);
};

export const sortDesc = (a: string | number, b: string | number): number => {
    if (a === null || a === "") {
        return 1;
    }

    if (b === null || b === "") {
        return -1;
    }

    if (a > b) {
        return -1;
    }
    return ((a < b) ? 1 : 0);
};


export const sortFormFieldsAsc = (a: FormField, b: FormField): number => sortAsc(a.fieldLabel.toLowerCase(), b.fieldLabel.toLowerCase());
export const sortFormFieldsDesc = (a: FormField, b: FormField): number => sortDesc(a.fieldLabel.toLowerCase(), b.fieldLabel.toLowerCase());
export const sortFormFiledByYPos = (a: FormField | DropdownItem | FormEmptyField, b: FormField | DropdownItem | FormEmptyField): number => sortAsc(a.yPosition, b.yPosition);
export const sortFormFiledByXPos = (a: FormField | FormEmptyField, b: FormField | FormEmptyField): number => sortAsc(a.xPosition, b.xPosition);

export const sortSectionsAsc = (a: SectionInfo, b: SectionInfo): number => sortAsc(a.sectionUiLabel.toLowerCase(), b.sectionUiLabel.toLowerCase());
export const sortOptionsAsc = (a: SelectOption, b: SelectOption): number => sortAsc(a.uiLabel.toLowerCase(), b.uiLabel.toLowerCase());

export const sortTableAsc = (sortColumn: string) => {
    return (a: {[key: string]: TableCell}, b: {[key: string]: TableCell}): number => {
        return sortAsc(a[sortColumn].rawValue, b[sortColumn].rawValue);
    };
};
export const sortTableDesc = (sortColumn: string) => {
    return (a: {[key: string]: TableCell}, b: {[key: string]: TableCell}): number => {
        return sortDesc(a[sortColumn].rawValue, b[sortColumn].rawValue);
    };
};

export const sortViewHistoryDataAsc = (sortColumn: string) => {
    return (a: {[key: string]: string}, b: {[key: string]: string}): number => {
        return sortAsc(a[sortColumn], b[sortColumn]);
    };
};
export const sortViewHistoryDataDesc = (sortColumn: string) => {
    return (a: {[key: string]: string}, b: {[key: string]: string}): number => {
        return sortDesc(a[sortColumn], b[sortColumn]);
    };
};
