import * as _ from "lodash";
import {FormEmptyField, FormField} from "@medispend/common/src/formBuilder/types";
import {ListItem} from "../common/types";


export const getFieldIndexById = (array: (FormField | FormEmptyField) [], comparisonField: FormField) => {
    return array.findIndex(field => {
        return field.fieldId === comparisonField.fieldId;
    });
};
export const getFieldIndexByCode = (array: ListItem [], comparisonCode: ListItem) => {
    return array.findIndex(field => {
        return field.code === comparisonCode.code;
    });
};
export const getUpdatedField = (field: FormField, replacedField: FormField) => {
    return {..._.cloneDeep(field), xPosition: replacedField.xPosition, yPosition: replacedField.yPosition};
};
