import React, {FC, useCallback, useState, useEffect} from "react";
import {useDrop} from "react-dnd";
import classNames from "classnames";
import * as _ from "lodash";

import {FormField, FormEmptyField, SectionInfo} from "@medispend/common/src/formBuilder/types";
import {sortFormFiledByYPos, sortFormFiledByXPos} from "@medispend/common/src/utils/sort";
import {FIELD_TYPE_COMPONENTS} from "./constants";
import {getFieldIndexById, getUpdatedField} from "../helpers";
import {EmptyField} from "./formFields/EmptyField";


interface SectionFieldsProps {
    sectionInfo: SectionInfo,
    onEditField: (field: FormField) => void,
    onRemoveField: (field: FormField) => void,
    handleChangeSectionInfo: (sectionInfo: SectionInfo) => void,
    hiddenFields: (FormField | FormEmptyField)[],
    editableField: FormField
}

export const SectionFields: FC<SectionFieldsProps> = ({sectionInfo, onEditField, onRemoveField, handleChangeSectionInfo, hiddenFields, editableField}: SectionFieldsProps) => {
    const [fields, setFields] = useState<(FormField | {xPosition: number, yPosition: number, fieldId: string}) []>(sectionInfo.fields?.sort(sortFormFiledByYPos));

    const moveField = useCallback((dragField, dropField) => {
        const fieldsArray: FormField[] = _.cloneDeep(fields) as FormField[];
        const dragFieldIndex = getFieldIndexById(fieldsArray, dragField);
        const dropFieldIndex = getFieldIndexById(fieldsArray, dropField);
        if (dragFieldIndex === -1 || dropFieldIndex === -1) {
            return false;
        }

        //fields swapping
        fieldsArray[dragFieldIndex] = {...getUpdatedField(dragField, dropField), fieldSize: dropField.fieldSize};
        fieldsArray[dropFieldIndex] = getUpdatedField(dropField, dragField);

        handleChangeSectionInfo({...sectionInfo, fields: [...fieldsArray.sort(sortFormFiledByYPos).filter(field => (field as FormField).fieldType), ...hiddenFields]});
    }, [fields, handleChangeSectionInfo, hiddenFields, sectionInfo]);

    const getEmptyCell = (x: number, y: number) => {
        return {xPosition: x, yPosition: y, fieldId: Math.floor(Math.random() * 100).toString(), fieldSize: 1};
    };

    const updateFieldsList = (fieldsList: (FormField | FormEmptyField)[]) => {
        const rowsList: (FormField | FormEmptyField) [][] = [];
        _.cloneDeep(fieldsList.filter(field => (field as FormField).fieldType))?.sort(sortFormFiledByYPos).forEach((item: FormField) => {
            rowsList[item.yPosition - 1] ? rowsList[item.yPosition - 1].push(item) : rowsList[item.yPosition - 1] = [item];
        });

        // add empty cells (right side/left side) to grid to change field position by X axis
        rowsList.forEach((column, index) => {
            const fieldSize = column.map((a: FormField) => a.fieldSize).reduce((acc, item) => acc + item);
            if (fieldSize < sectionInfo.sectionWidth) {
                const posibleXpos = Array.from({length: sectionInfo.sectionWidth}, (_, i) => i + 1);
                const xPosList: number[] = [];
                column.forEach((item: FormField) => {
                    xPosList.push(...Array.from({length: item.fieldSize}, (_, i) => item.xPosition + i));
                });
                const emptyCellsXpos = _.difference(posibleXpos, xPosList);
                for (let i = 0; i < emptyCellsXpos.length; i++) {
                    const emptyCell = getEmptyCell(emptyCellsXpos[i], column[0].yPosition);
                    column.push(emptyCell);
                    fieldsList.push(emptyCell);
                }
            }
            column.sort(sortFormFiledByXPos);
            const fieldFormInRow = column.find(field => (field as FormField).fieldType);
            if (!fieldFormInRow) {
                rowsList.splice(index, 1);
            }
        });
        const updatedRowsList = rowsList.filter((row) => row !== undefined);

        // add empty rows to grid for reordering by Y axis
        const newRowsList = _.cloneDeep(updatedRowsList);
        for (let i = 0; i < updatedRowsList.length * 2 + 1; i++ ) {
            if (i === 0 || i % 2 === 0) {
                newRowsList.splice(i, 0, updatedRowsList[0].map(row => (getEmptyCell(row.xPosition, i + 1))));
            }
        }
        newRowsList.forEach((row, index) => {
            newRowsList[index].forEach((field, fieldIndex) => {
                newRowsList[index][fieldIndex] = {...field, yPosition: index + 1};
            });
        });
        const updatedList = newRowsList.reduce((acc, item) => {
            return [...acc, ...item];
        }, []);

        return {updatedFields: updatedList, updatedRowsList: newRowsList};
    };

    const resizeField = (fieldSize: number, field: FormField) => {
        if (fieldSize <= sectionInfo.sectionWidth) {
            const fieldsList: (FormField | FormEmptyField) [] = _.cloneDeep(fields)?.sort(sortFormFiledByYPos);
            const updatedFieldItemIndex = getFieldIndexById(fieldsList, field);
            fieldsList[updatedFieldItemIndex] = {...field, fieldSize};
            handleChangeSectionInfo({...sectionInfo, fields: [...fieldsList.sort(sortFormFiledByYPos).filter(field => (field as FormField).fieldType), ...hiddenFields]});
        }
    };

    const [rows, setRows] = useState([]);

    useEffect(() => {
        const fields: (FormField | FormEmptyField) [] = _.cloneDeep(sectionInfo.fields)?.sort(sortFormFiledByYPos).filter(field => (field as FormField).fieldType);
        const {updatedFields, updatedRowsList} = updateFieldsList(fields);
        setFields(updatedFields);
        setRows(updatedRowsList);
    }, [sectionInfo.fields, sectionInfo.sectionWidth]);

    const [{canDrop}, drop] = useDrop({
        accept: "card",
        collect: (monitor) => ({
            canDrop: monitor.canDrop()
        })
    }, [rows]);
    return (<div className="section-fields">
        <h3>{sectionInfo.sectionUiLabel} <span>(Configure {sectionInfo.sectionWidth} field(s) per row)</span></h3>
        <div ref={drop} className={classNames("section-fields-container", "form-section", `col-num-${sectionInfo.sectionWidth}`)}
            style={{width: sectionInfo.sectionWidth * 300 + 28, columnGap: sectionInfo.sectionWidth === 1 ? 0 : "5px"}}>

            {rows?.map((sectionFields, rowIndex) => {
                return sectionFields.map((field: FormField, index: number) => {
                    if (field.fieldType) {
                        const isEmptySpaceInRow = (sectionInfo.sectionWidth - sectionFields.filter((field: FormField) => field.fieldType).reduce((acc: number, item: FormField) => acc + item.fieldSize, 0)) > 0;
                        const isEmptySpaceBeforeField = !sectionFields.filter((field: FormField) => field.fieldType).find((item: FormField) => item.xPosition === field.xPosition + field.fieldSize);
                        const FieldViewComponent = FIELD_TYPE_COMPONENTS[field.fieldType].fieldView;
                        return (
                            <div key={field.fieldId}
                                style={{gridRow: rowIndex + 1}}
                                className={classNames(`col-start-${field.xPosition}`, `col-end-${field.xPosition + field.fieldSize}`, "ms-form-element")}>
                                <FieldViewComponent field={field} key={field.fieldId} id={field.fieldId}
                                    moveField={moveField} onEditField={onEditField}
                                    onRemoveField={onRemoveField} resizeField={resizeField}
                                    maxWidth={sectionInfo.sectionWidth}
                                    isEmptySpace={isEmptySpaceInRow && isEmptySpaceBeforeField}
                                    isEditable={FIELD_TYPE_COMPONENTS[field.fieldType].isEditable}
                                    isActive={field.fieldId === editableField?.fieldId}
                                />
                            </div>
                        );
                    } else {
                        return (
                            <div key={index}
                                style={{gridRow: rowIndex + 1}}
                                className={classNames(`col-start-${field.xPosition}`, `col-end-${field.xPosition + 1} grid-row-${rowIndex + 1}`)}>
                                <EmptyField rowIndex={rowIndex} field={field} onRemoveField={onRemoveField} id={field.fieldId} moveField={moveField} />
                            </div>
                        );
                    }
                });
            })}
        </div>
    </div>);
};
