import React, {FC} from "react";
import {FormikValues} from "formik";
import * as Yup from "yup";
import * as _ from "lodash";

import MSInput from "@medispend/common/src/components/MSInput";
import {MSFormSelect} from "@medispend/common/src/components/MSFormSelect";
import {ButtonColors} from "@medispend/common/src/components/MSButton/types";
import {getRequiredMessage, getNumericMessage} from "@medispend/common/src/constants";
import {REQUIRED_TYPE} from "../constants";
import {FormField} from "@medispend/common/src/formBuilder/types";
import {TextAreaTypeField, YupObject} from "../../common/types";
import {useRegisterFields} from "../hooks/useRegisterFields";


interface TextTypeFieldsProps {
    formik: FormikValues,
    handleChangeFieldType: (validationRules: YupObject) => void,
    editableField: FormField
}

export const TextAreaTypeFields: FC<TextTypeFieldsProps> = ({formik, handleChangeFieldType, editableField}) => {
    const {errors, touched, values} = formik;
    const initialValues: TextAreaTypeField = {
        maxFieldLength: null,
        defaultValue: "",
        requiredType: "OPTIONAL"
    };
    const editableFieldValues = _.pick(editableField, ["maxFieldLength", "defaultValue", "requiredType"]);
    const validationSchema = {
        maxFieldLength: Yup.number().typeError(getNumericMessage("Maximum Length"))
            .required(getRequiredMessage("Maximum Length"))
            .nullable(),
        defaultValue: Yup.string().nullable(),
        requiredType: Yup.mixed()
            .required(getRequiredMessage("Required Type"))
    };
    const fieldsList = ["maxFieldLength", "defaultValue", "requiredType"];
    useRegisterFields(handleChangeFieldType, validationSchema, initialValues, (editableFieldValues as TextAreaTypeField), fieldsList, formik);

    return (<>
        <MSInput
            name="maxFieldLength"
            fieldLabel="Maximum Length"
            isRequired={false}
            isDisabled={false}
            error={(touched.maxFieldLength && errors.maxFieldLength) || ""}
            inputType="number"
            value={values.maxFieldLength}
            handleChange={(value) => formik.setFieldValue("maxFieldLength", value)}
            variant="grid"
        />
        <MSInput
            name="defaultValue"
            fieldLabel="Default Value"
            isRequired={false}
            isDisabled={false}
            error={(touched.defaultValue && errors.defaultValue) || ""}
            value={values.defaultValue}
            handleChange={(value) => formik.setFieldValue("defaultValue", value)}
            isHandleAction
            variant="grid"
        />
        <MSFormSelect
            name="requiredType"
            isDisabled={false}
            activeVariant={null}
            options={REQUIRED_TYPE}
            fieldLabel="Required Field"
            placeholder="Select"
            value={values.requiredType}
            isRequired={false}
            error={(touched.requiredType && errors.requiredType) || ""}
            placeVariant="grid"
            size="md"
            handleChange={(value) => formik.setFieldValue("requiredType", value)}
            variant={ButtonColors.white}
        />
    </>);
};
