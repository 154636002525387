import {useEffect} from "react";
import {zip} from "rxjs";
import { mapActuatorJson } from "@medispend/common/src/utils/version";
import { CFF_API_NAME, CFF_STORAGE_KEY } from "@medispend/common/src/components/MSVersionModal/constants";
import { getAjax } from "../services/ajax";

export const useGetVersionData = (): void => {
    useEffect(() => {
        zip(getAjax("/api/actuator/info")).subscribe(([response]) => {
            localStorage.setItem(CFF_STORAGE_KEY, JSON.stringify(mapActuatorJson(response.data, CFF_API_NAME)));
        });
    }, []);
};
