import React, {useEffect, useRef} from "react";
import {ComponentsTypes} from "../../formBuilder/constantsLib";
import {Button, FieldHandleAction} from "../../formBuilder/types";
import {MSLabel} from "../MSLabel";
import "./styles.scss";

interface Props {
    value: string | number;
    fieldLabel: string;
    isRequired: boolean;
    displayValue?: string;
    updatedDisplayValue?: string;
    error: string;
    button?: Button;
    fieldType: ComponentsTypes;
    currency?: string;
    name?: string;
    handleAction?: FieldHandleAction;
    isHandleAction?: boolean;
    handleChange?: (value: string | number, name: string, isHandleAction: boolean, handleAction: FieldHandleAction) => void;
}
export function MSReadOnly({
    fieldLabel,
    displayValue,
    updatedDisplayValue,
    isRequired,
    error,
    value,
    button,
    fieldType,
    currency,
    name,
    handleAction,
    isHandleAction,
    handleChange
}: Props): JSX.Element {
    const Button = button?.component;
    const isHyperlink = fieldType === ComponentsTypes.HYPERLINK;
    const prevValue = useRef(null);

    useEffect(() => {
        if (handleChange && prevValue.current) {
            handleChange(value, name, isHandleAction, handleAction);
        }
        prevValue.current = true;
    }, [value]);

    return (
        <div className="readonly">
            <div className="ms-form-group">
                {fieldLabel && (
                    <MSLabel isRequired={isRequired} fieldLabel={fieldLabel} isPencilDisplayed={false} error={error} />
                )}
                <div className="value">
                    {isHyperlink ? (
                        updatedDisplayValue || displayValue || value ? <a href={String(value)} target="_blank" rel="noreferrer">
                            {updatedDisplayValue || displayValue || value}
                        </a> : "-"
                    ) :
                        <>
                            {currency && value ? currency + " " : null}
                            {value === undefined || value === null || value === "" ? "-" : value}
                        </>
                    }
                </div>
                {!!error?.length && <div className="error-message">{error}</div>}
            </div>
            {Button && (
                <Button
                    actionHandler={button.actionHandler}
                    onClick={button.actionHandler}
                    label={button.uiLabel}
                    isDisabled={button.isDisabled}
                    title={button.uiLabel}
                />
            )}
        </div>
    );
}
