import React, {FC, useEffect, useState} from "react";
import {Prompt} from "react-router-dom";
import * as H from "history";

import {useGlobalModalContext} from "../MSGlobalModal";
import {MODAL_TYPES} from "../MSGlobalModal/constants";


interface RouteLeavingGuardProps {
    navigate: (nextLocation: string) => void,
    when: boolean,
    shouldBlockNavigation: (nextLocation: H.Location) => boolean,
    nextLocation: string,
    onCancel?: () => void
}

export const RouteLeavingGuard: FC<RouteLeavingGuardProps> = ({
    navigate,
    when,
    shouldBlockNavigation, nextLocation, onCancel}) => {
    const [modalVisible, updateModalVisible] = useState(!!nextLocation);
    const [lastLocation, updateLastLocation] = useState<H.Location>();
    const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

    const {showModal: showGlobalModal} = useGlobalModalContext();

    const showModal = (location: H.Location) => {
        updateModalVisible(true);
        updateLastLocation(location);
    };

    const closeModal = (closeModalHandler: () => void) => {
        updateModalVisible(false);
        if (closeModalHandler) {
            closeModalHandler();
        }
    };

    const handleBlockedNavigation = (nextLocation: H.Location) => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            showModal(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = () => {
        closeModal(() => {
            if (lastLocation || nextLocation) {
                updateConfirmedNavigation(true);
            }
        });
    };

    useEffect(() => {
        if (confirmedNavigation) {
            nextLocation ? navigate(nextLocation) : navigate(lastLocation.pathname);
            updateConfirmedNavigation(false);
        }
        updateModalVisible(false);
    }, [confirmedNavigation]);

    useEffect(() => {
        updateModalVisible(!!nextLocation);
    }, [nextLocation]);

    useEffect(() => {
        modalVisible && showGlobalModal(MODAL_TYPES.CONFIRM_MODAL, {
            title: "You have unsaved changes",
            text: "Are you sure you want to continue?",
            onConfirm: handleConfirmNavigationClick,
            onCancel: () => {
                onCancel();
                updateModalVisible(false);
            }
        });
    }, [modalVisible]);

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
        </>
    );
};

export default RouteLeavingGuard;
