import * as Yup from "yup";
import {AnyObjectSchema, AnySchema, ArraySchema, NumberSchema, StringSchema} from "yup";

import {ComponentsTypes, InputMask, YupByType} from "../constantsLib";
import {Column, FormField, Section} from "../types";
import {ResponseState} from "../../types";
import {isDynamicSection} from "../utils";
import {getAlphabeticMessage, getNumericMessage} from "../../constants";
import {testAlphabeticString} from "../../constants/yupCustomMethods";

export const getBaseValidation = (field: FormField): Yup.AnySchema => {
    const type: ComponentsTypes = field.isReadOnly ? ComponentsTypes.READ_ONLY : field.fieldType;
    const isFieldSelectable = !!field.options || field.fieldType === ComponentsTypes.DATE;
    const endOfRequiredMessage = (isFieldSelectable || type === ComponentsTypes.READ_ONLY) ? "Please select a value." : "Please enter a value.";
    let validationRules = YupByType[type](field).nullable();

    const maxLength = field.fieldLength || field.maxFieldLength;
    if (type !== ComponentsTypes.READ_ONLY && maxLength > 0) {
        validationRules = (validationRules as StringSchema<string, AnyObjectSchema, string>).max(maxLength, `${field.fieldLabel} too long`);
    }
    if (field.requiredType === "ALWAYS") {
        const validationMessage = `${field.fieldLabel} is required. ${endOfRequiredMessage}`;
        validationRules = validationRules.required(validationMessage);
        if (field.fieldType === ComponentsTypes.CUSTOM_MULTISELECT || field.fieldType === ComponentsTypes.STANDARD_MULTISELECT) {
            validationRules = validationRules.hasOwnProperty('min')
                ? (validationRules as ArraySchema<AnySchema>).min(1, validationMessage)
                : YupByType[type](field).nullable();
        }
    }

    return validationRules;
};

const getSectionValidateSchema = (section: Section) => section.fields.filter((field: FormField) => !field.isHidden).reduce((acc: any, item: FormField) => {
    acc[item.fieldId] = getBaseValidation(item);
    return acc;
}, {});

export const getSectionsValidateSchema = (sectionsArr: Section[]) => {
    const sectionsValidateSchema = sectionsArr.reduce((acc: any, item: Section) => {
        acc[item.sectionId] = Yup.object().shape({
            ...getSectionValidateSchema(item)
        });
        return acc;
    }, {});
    return Yup.object().shape(sectionsValidateSchema);
};

export const getResponseWithUpdatedValue = (response: ResponseState) => {
    response.data.columns.forEach((column: Column) => {
        column.sections.filter(isDynamicSection).forEach((section) => {
            section.fields.forEach(field => {
                const value = field.value !== null ? field.value : field.defaultValue;
                field.defaultValue = null;
                field.value = value;
            });
        });
    });
    return response;
};

export const getTextFieldSchemasByField = (field: FormField): StringSchema<string> | NumberSchema<number> => {
    let result: StringSchema<string> | NumberSchema<number> = Yup.string().notRequired().nullable().trim();
    if (field && field.inputMask) {
        const schemas = {
            [InputMask.ALPHANUMERIC]: () => result,
            [InputMask.ALPHABETIC]: () => Yup.string().trim().test(
                InputMask.ALPHABETIC.toLowerCase(), getAlphabeticMessage(field.fieldLabel), testAlphabeticString),
            [InputMask.NUMERIC]: () => Yup.number().typeError(getNumericMessage(field.fieldLabel)),
            [InputMask.REGEX]: () => getRegexSchemaByField(field)
        };
        result = schemas[field.inputMask]();
    }
    return result;
};

export const getRegexSchemaByField = (field: FormField): StringSchema<string> => {
    console.log("getRegexSchemaByField(field.inputMaskPattern: ", field.inputMaskPattern);
    return getRegexSchema(field.inputMaskPattern, field.toolTip);
};
export const getRegexSchema = (inputMaskPattern: string, toolTip: string): StringSchema<string> => {
    console.log("getRegexSchema(inputMaskPattern: ", inputMaskPattern);
    let regExp = null;
    if (inputMaskPattern) {
        try {
            regExp = new RegExp(inputMaskPattern);
        } catch (e) {
            console.log("Input pattern is not a correct RegExp");
        }
    }
    return (regExp ? Yup.string()
        .matches(regExp, toolTip || `Pattern must match: '${inputMaskPattern}'`) : Yup.string());
};
