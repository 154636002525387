import React from "react";
import {Column, Cell} from "react-table";

import {MSMultiSelect} from "@medispend/common/src/components/MSMultiSelect";
import {FilterItem, FilterParams, FilterValue} from "@medispend/common/src/types";
import {IconFolders, IconTypes, MSIcon} from "@medispend/common/src/components/MSIcon";
import {MSButton} from "@medispend/common/src/components/MSButton";
import {ButtonColors, ButtonSizes} from "@medispend/common/src/components/MSButton/types";
import {TableHeader} from "@medispend/admin-common/src/components/TableHeader";
import {ScreenSort} from "@medispend/admin-common/src/types";
import {isMedispendSubDom} from "@medispend/admin-common/src/env";
import {RequestBodyFilters, CffScreenFilters, CFFContentItem} from "../common/types";


export const DEFAULT_REQUEST_BODY: RequestBodyFilters<CffScreenFilters> = {
    filters: {
        ...(isMedispendSubDom ? [{clients: null}] : []),
        processes: null,
        types: null
    },
    searchQuery: ""
};

export const DEFAULT_CFF_FILTERS: Array<FilterItem<FilterValue>> = [
    ...(isMedispendSubDom ? [{value: null, name: "clients"}] : []),
    {value: null, name: "processes"},
    {value: null, name: "types"}
];

export const CFF_FILTERS: FilterParams[] = [
    ...(isMedispendSubDom ? [{
        title: "Client",
        name: "clients",
        component: MSMultiSelect,
        isMulti: true,
        options: []
    }] : []),
    {
        title: "Process",
        name: "processes",
        component: MSMultiSelect,
        isMulti: true,
        options: []
    },
    {
        title: "Type",
        name: "types",
        component: MSMultiSelect,
        isMulti: true,
        options: []
    }
];

export const getCffColumns = (sortOptions: ScreenSort, setSort: (sortOptions: ScreenSort) => void, redirectToScreen: (screenId: string, clientId: string) => void): Array<Column<Record<string, unknown>>> => [
    ...(isMedispendSubDom ? [{
        id: "clientUiLabel",
        Header: <TableHeader columnName="client" label="Client" sortOptions={sortOptions} setSort={setSort} />,
        accessor: "clientUiLabel"
    }] : []),
    {
        id: "processName",
        Header: <TableHeader columnName="process" label="Process" sortOptions={sortOptions} setSort={setSort} />,
        accessor: "processName"
    },
    {
        id: "screenName",
        Header: <TableHeader columnName="screen" label="Screen Name" sortOptions={sortOptions} setSort={setSort} />,
        accessor: "screenName"
    },
    {
        id: "type",
        Header: <div className="column-header">Type</div>,
        accessor: "type"
    },
    {
        id: "view-layout-button",
        width: 5,
        Cell: function getButton(cell: Cell<CFFContentItem>) {
            const {row: {original}} = cell;
            return ( <MSButton
                size={ButtonSizes.md}
                variant={ButtonColors.green}
                onClick={() => {
                    redirectToScreen(original.screenId, original.clientId);
                }}>
                <div className="ms-button-title">
                    <MSIcon
                        icon="white-column"
                        iconType={IconTypes.MS}
                        iconFolder={IconFolders.UTILS}
                    />
                    View Layout
                </div>
            </MSButton>);
        }
    }
];

export const DEFAULT_SORT: ScreenSort = {
    ...(isMedispendSubDom ? {client: "asc"} : {}),
    process: null,
    screen: null
};

export const SEARCH_TYPE = {
    value: "searchQuery",
    uiLabel: "Screen Name",
    name: "Name"
};
