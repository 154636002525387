import React, {FC} from "react";
import {FormikValues} from "formik";
import * as Yup from "yup";
import * as _ from "lodash";

import MSInput from "@medispend/common/src/components/MSInput";
import {InputMask} from "@medispend/common/src/formBuilder/constantsLib";
import {MSFormSelect} from "@medispend/common/src/components/MSFormSelect";
import {ButtonColors} from "@medispend/common/src/components/MSButton/types";
import {getNumericMessage} from "@medispend/common/src/constants";
import {FormField} from "@medispend/common/src/formBuilder/types";
import {TextTypeField, YupObject} from "../../common/types";
import {useRegisterFields} from "../hooks/useRegisterFields";
import {REQUIRED_TYPE} from "../constants";
import {getTextFieldSchemasByField} from "@medispend/common/src/formBuilder/helpers/validationHelpers";


interface TextTypeFieldsProps {
    formik: FormikValues,
    handleChangeFieldType: (validationRules: YupObject) => void,
    editableField: FormField
}

export const TextTypeFields: FC<TextTypeFieldsProps> = ({formik, handleChangeFieldType, editableField}) => {
    const {errors, touched, values} = formik;
    const INPUT_MASK_LIST = [
        {uiLabel: "Alphabetic", value: InputMask.ALPHABETIC},
        {uiLabel: "Alphanumeric", value: InputMask.ALPHANUMERIC},
        {uiLabel: "Input Pattern", value: InputMask.REGEX}
    ];
    const initialValues: TextTypeField = {
        inputMask: InputMask.ALPHANUMERIC,
        inputMaskPattern: null,
        maxFieldLength: null,
        defaultValue: "",
        requiredType: "OPTIONAL"
    };
    const INPUT_MASK = "inputMask";
    const INPUT_MASK_PATTERN = "inputMaskPattern";
    const editableFieldValues = _.pick(editableField, [INPUT_MASK, "maxFieldLength", "defaultValue", "requiredType"]);

    const validationSchema = {
        maxFieldLength: Yup.number().typeError(getNumericMessage("Maximum Length")).nullable(),
        defaultValue: getTextFieldSchemasByField(editableField).notRequired().nullable()
    };
    const fieldsList = [INPUT_MASK, "maxFieldLength", "defaultValue", "requiredType"];

    useRegisterFields(handleChangeFieldType, validationSchema, initialValues, (editableFieldValues as TextTypeField), fieldsList, formik);

    const isRegex = values.inputMask == InputMask.REGEX;

    return (<>
        <MSFormSelect
            name="inputMask"
            isDisabled={false}
            activeVariant={null}
            options={INPUT_MASK_LIST}
            fieldLabel="Input Mask"
            placeholder="Select Input Mask"
            value={values.inputMask}
            isRequired={false}
            error={(touched.inputMask && errors.inputMask) || ""}
            placeVariant="grid"
            size="md"
            handleChange={(value) => formik.setFieldValue(INPUT_MASK, value)}
            variant={ButtonColors.white}
        />
        { isRegex && <>
                <MSInput
                    name="inputMaskPattern"
                    fieldLabel="Input Pattern"
                    isRequired={false}
                    isDisabled={false}
                    error={(touched.inputMaskPattern && errors.inputMaskPattern) || ""}
                    value={values.inputMaskPattern}
                    handleChange={(value) => formik.setFieldValue("inputMaskPattern", value)}
                    isHandleAction
                    variant="grid"
                />
                <MSInput
                    name="toolTip"
                    fieldLabel="Tool Tip"
                    isRequired={false}
                    isDisabled={false}
                    error={(touched.toolTip && errors.toolTip) || ""}
                    value={values.toolTip}
                    handleChange={(value) => formik.setFieldValue("toolTip", value)}
                    isHandleAction
                    variant="grid"
                />
            </>
        }
        <MSInput
            name="maxFieldLength"
            fieldLabel="Maximum Length"
            isRequired={false}
            isDisabled={false}
            error={(touched.maxFieldLength && errors.maxFieldLength) || ""}
            inputType="number"
            value={values.maxFieldLength}
            handleChange={(value) => formik.setFieldValue("maxFieldLength", value)}
            variant="grid"
        />
        <MSInput
            name="defaultValue"
            fieldLabel="Default Value"
            isRequired={false}
            isDisabled={false}
            error={(touched.defaultValue && errors.defaultValue) || ""}
            value={values.defaultValue}
            handleChange={(value) => formik.setFieldValue("defaultValue", value)}
            isHandleAction
            variant="grid"
        />
        <MSFormSelect
            name="requiredType"
            isDisabled={false}
            activeVariant={null}
            options={REQUIRED_TYPE}
            fieldLabel="Required Field"
            placeholder="Select"
            value={values.requiredType}
            isRequired={false}
            error={(touched.requiredType && errors.requiredType) || ""}
            placeVariant="grid"
            size="md"
            handleChange={(value) => formik.setFieldValue("requiredType", value)}
            variant={ButtonColors.white}
        />
    </>);
};
