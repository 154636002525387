import React, {ReactElement, useLayoutEffect} from "react";

import {FormField} from "@medispend/common/src/formBuilder/types";
import {useDragAndDrop} from "@medispend/common/hooks/useDragAndDrop";
import {IconTypes, MSIcon} from "@medispend/common/src/components/MSIcon";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


interface TextTypeViewProps {
    field: FormField,
    onEditField?: (field: FormField) => void
    onRemoveField: (field: FormField) => void,
    id: string,
    moveField: (dragIndex: FormField, hoverIndex: FormField) => FormField[],
    resizeField: (fieldSize: number, field: FormField) => void,
    maxWidth: number,
    isEditable: boolean,
    isActive?: boolean
    isEmptySpace?: boolean
}

export const TextTypeView = ({field, onEditField, onRemoveField, moveField, resizeField, maxWidth, isEditable, isActive, isEmptySpace}: TextTypeViewProps): ReactElement<TextTypeViewProps> => {

    const {opacity, ref, drag} = useDragAndDrop(moveField, field);
    drag(ref);

    useLayoutEffect(() => {
        if (ref.current) {
            ref.current.setAttribute("draggable", "true");
        }
    }, [field, ref]);

    return <div className={`section-field ${isActive && "active-field"}`} ref={ref} style={{opacity}} onClick={() => isEditable && onEditField(field)} draggable>
        <span className="section-field__label">{field.fieldLabel}</span>
        <div className="icons-area">
            {field.isHideable && !field.usedInBizRules?.length && (
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        onRemoveField && onRemoveField({...field, isHidden: true});
                    }}
                    data-testid="remove-button"
                >
                    <img src="../../../../media/img/icons/red-cross.svg" width={13} height={13} alt="Close" />
                </button>
            )}
            {!!field.usedInBizRules?.length && (
                <OverlayTrigger overlay={
                    <Tooltip className="tooltip-wide">Business Rules {field.usedInBizRules.map(bizName => `"${bizName}"`).join(", ")} is/are
                        currently using {field.fieldLabel}.
                        Please modify the business rule(s) if this field needs to be removed from the screen.
                    </Tooltip>} placement="top">
                    <button data-testid="used-in-br-icon">
                        <MSIcon className="info-icon" icon="info-circle" />
                    </button>
                </OverlayTrigger>
            )}
            {maxWidth > 1 && (
                <div className="arrows-wrapper">
                    {field.fieldSize > 1 && <MSIcon icon="angle-left" iconType={IconTypes.FA} onClick={(e) => {
                        e.stopPropagation();
                        resizeField(field.fieldSize - 1, field);
                    }} />}
                    {field.fieldSize < maxWidth && isEmptySpace && <MSIcon icon="angle-right" iconType={IconTypes.FA} onClick={(e) => {
                        e.stopPropagation();
                        resizeField(field.fieldSize + 1, field);
                    }} />}
                </div>
            )}
        </div>
    </div>;
};
